import gql from 'graphql-tag';

export const GET_BILLING = gql`
  query billing($id: ID!) {
    billing(id: $id) {
      id
      ammount
      billingFile
      firstName
      lastName
      email
      phone
      billingStreet
      billingCity
      billingZipCode
      dpp
      dppDeclaration
      personalIdentificationNumber
      crn
      vatin
      bankAccount
      issuedAt
      dueAt
      variableNumber
      createdAt
      updatedAt
      user {
        id
      }
      client {
        id
        name
      }
      issuedBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_BILLINGS = gql`
  {
    billings {
      id
      ammount
      firstName
      lastName
      phone
      variableNumber
      client {
        id
        name
      }
    }
  }
`;

export const GET_INVOICE = gql`
  query invoice($id: ID!) {
    invoice(id: $id)
  }
`;

export const GET_BILLINGS_DPP_SUMMARY_REPORT = gql`
  query billingsDppSummaryExcel($period: String!) {
    billingsDppSummaryExcel(period: $period)
  }
`;

export const GET_BILLINGS_SUMMARY_EXCEL_FOR_MANAGER = gql`
  query billingsSummaryExcelForManager {
    billingsSummaryExcelForManager
  }
`;

export const GET_BILLINGS_CRN_SUMMARY_REPORT = gql`
  query billingsCrnSummaryExcel($period: String!) {
    billingsCrnSummaryExcel(period: $period)
  }
`;

export const GET_CLIENT = gql`
  query client($id: ID!) {
    client(id: $id) {
      id
      name
      dueDate
    }
  }
`;

export const GET_CLIENTS = gql`
  {
    clients {
      id
      name
      dueDate
      createdAt
    }
  }
`;

export const GET_ME = gql`
  query {
    me {
      id
      role
    }
  }
`;

export const GET_MONTHLY_REPORTS = gql`
  {
    monthlyReports {
      month
      billingsSum
      clients {
        name
        dppSum
        crnSum
      }
    }
  }
`;

export const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      phone
      role
      billingStreet
      billingCity
      billingZipCode
      dpp
      dppDeclaration
      personalIdentificationNumber
      crn
      vatin
      bankAccount
      billings {
        id
        ammount
        variableNumber
        issuedAt
        dpp
        crn
        client {
          id
          name
        }
        issuedBy {
          id
          firstName
          lastName
        }
      }
      manager {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_USERS = gql`
  {
    users {
      id
      firstName
      lastName
      email
      phone
      billingStreet
      billingCity
      billingZipCode
      role
      lastBilling
      dpp
      manager {
        id
        firstName
        lastName
      }
    }
  }
`;
