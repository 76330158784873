import React, { useState } from 'react';
import * as Joi from '@hapi/joi';

const ValidatorPage = () => {
  const [data, setData] = useState('');
  const [validationResponse, setValidationResponse] = useState({});
  const [error, setError] = useState(null);

  const sanitazeEmailAddress = (email) => email.toLowerCase().trim();

  const schema = Joi.array()
    .unique((a, b) => a.email === b.email)
    .items(
      Joi.object().keys({
        email: Joi.string().email().required(),
        firstName: Joi.string().required(),
        lastName: Joi.string().required(),
        phone: Joi.string().required(),
        billingStreet: Joi.string().required(),
        billingCity: Joi.string().required(),
        billingZipCode: Joi.number().integer().min(10000).max(99999).required(),
        dpp: Joi.string().allow('0', '1').required(),
        dppDeclaration: Joi.when('dpp', {
          is: true,
          then: Joi.string().allow('0', '1').required(),
        }),
        personalIdentificationNumber: Joi.when('dpp', {
          is: true,
          then: Joi.string()
            .regex(/^[0-9]{6}\/[0-9]{3,4}$/)
            .required(),
        }),
        crn: Joi.when('dpp', {
          is: false,
          then: Joi.string()
            .regex(/^[0-9]{8}$/)
            .required(),
        }),
        vatin: Joi.when('dpp', {
          is: false,
          then: Joi.string()
            .regex(/^[A-Z]{2}[0-9]{8,10}$/)
            .required(),
        }),
        bankAccount: Joi.string()
          .regex(/^(([0-9]{2,6}-[0-9]{2,10})|([0-9]{2,10}))\/[0-9]{4}$/)
          .required(),
      }),
    )
    .min(1)
    .required();

  const onSumbit = () => {
    setValidationResponse({});
    setError(null);

    try {
      const json = JSON.parse(data);
      const array = json['List 1'];

      const mappedDataWithTransformedEmails = array.map((data) => ({
        ...data,
        email: sanitazeEmailAddress(data.email),
      }));

      const validation = Joi.validate(mappedDataWithTransformedEmails, schema, { abortEarly: false });

      setValidationResponse(validation);
    } catch (error) {
      setError('Obsah textového pole není validní. Máte špatné sloupce, nebo není první řádek List 1 viz níže.');
    }
  };

  console.log({ validationResponse });

  return (
    <div style={{ padding: 20 }}>
      <h1>Importer validator</h1>
      {error}

      {validationResponse &&
        validationResponse.error &&
        validationResponse.error.details &&
        validationResponse.error.details.map((error) => <div>{error.message}</div>)}

      <textarea
        rows={30}
        style={{ width: '100%' }}
        placeholder="Sem vložit obsah z http://beautifytools.com/excel-to-json-converter.php"
        onChange={(e) => setData(e.target.value)}
        value={data}
      ></textarea>

      <button onClick={() => onSumbit()}>Validovat</button>

      <hr />

      <h2>Návod na používání</h2>
      <ol>
        <li>Připravíme si Excel, který chceme zkoušet.</li>
        <li>
          Otevřeme{' '}
          <a href="http://beautifytools.com/excel-to-json-converter.php">
            http://beautifytools.com/excel-to-json-converter.php
          </a>{' '}
          a klikneme na tlačítko Browse. Vybereme excel soubor.
        </li>
        <li>
          Výsledek zkopírujeme do textového pole výše. Musíme zkontrolovat, že druhý řádek začíná takto: <br />
          "List 1": [
        </li>
        <li>Dáme validovat, nad polem bude výpis chyb.</li>
      </ol>

      <p>
        Ukázkový soubor s chybami:{' '}
        <a href="http://soubory.homoky.cz/PE4CfaBePfCPcXWdMBl.xlsx" target="_blank" rel="noopener noreferrer">
          zde
        </a>
      </p>

      <h2>Typy chyb</h2>
      <ul>
        <li>
          "bankAccount" with value "1178171143 / 0800" fails to match the required pattern: /^(([0-9]{(2, 6)}-[0-9]
          {(2, 10)})|([0-9]{(2, 10)}))\/[0-9]{4}$/ - bankovní účet má v hodnotě
        </li>
        <li>
          "value" position 1 contains a duplicate value - řádek 2 (pozice + 1 - počítání začíná od nuly u počítačů)
          obsahuje emailovou adresu, která je již před tím použita
        </li>
      </ul>

      <h2>Validační chyby</h2>
      <p>
        Nejdříve se validují chyby na úrovni objektu, až potom se validuje unikátnost emailu. Nejprvé vám to tedy vypíše
        pouze chyby v objektech bez upozornění na duplicity v emailových adresách. Jakmile opravíte, nahrajte nový
        export a ten dejte zvalidovat znovu.
      </p>
    </div>
  );
};

export default ValidatorPage;
