import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import apolloClient from './apollo-client';
import cookies from 'js-cookie';
import '@atlaskit/css-reset';
import 'moment/locale/cs';
import client from './apollo-client';
import getValue from 'get-value';
import EditBillingPage from './pages/editBilling';
import SetUserTokenPage from './pages/setUserToken';
import ClientsPage from './pages/clients';
import CreateAndEditClientPage from './pages/createAndEditClient';
import UsersPage from './pages/users';
import CreateAndEditUserPage from './pages/createAndEditUser';
import UserPage from './pages/user';
import CreateBillingPage from './pages/createBilling';
import MigrateMerchantsPage from './pages/migrate-merchants';
import BillingsPage from './pages/billings';
import BillingPage from './pages/billing';
import LoginPage from './pages/login';
import { GET_ME } from './graphqlQueries';
import ReportsPage from './pages/reports';
import ValidatorPage from './pages/validator';

const RedirectBasedOnUserRole = () => {
  const [, setRedirect] = useState(false);
  const [userResponse, setUserResponse] = useState(null);
  const [fetchedData, setFetchedData] = useState(false);

  const fetchUser = async () => {
    if (cookies.get('token')) {
      setUserResponse(await client.query({ query: GET_ME }).catch((e) => setRedirect(true)));
      setFetchedData(true);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  if (!userResponse && cookies.get('token') && !fetchedData) {
    return null;
  }

  if (!userResponse && cookies.get('token') && fetchedData) {
    cookies.remove('token');
    return <Redirect to="/login" />;
  }

  if (!userResponse && !cookies.get('token')) {
    return <Redirect to="/login" />;
  }

  if (!getValue(userResponse, 'data.me.id')) {
    cookies.remove('token');
    return <Redirect to="/login" />;
  }

  const user = userResponse.data.me;

  if (user.role === 'ADMIN' || user.role === 'MANAGER') {
    return <Redirect to="/users" />;
  }

  if (user.role === 'MERCHANT') {
    return <Redirect to={`/users/${user.id}`} />;
  }
};

const UserRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        cookies.get('token') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const App = () => (
  <ApolloProvider client={apolloClient}>
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={RedirectBasedOnUserRole} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/login/:token" component={SetUserTokenPage} />
          <UserRoute exact path="/clients" component={ClientsPage} />
          <UserRoute exact path="/clients/add" component={CreateAndEditClientPage} />
          <UserRoute exact path="/clients/:id/edit" component={CreateAndEditClientPage} />
          <UserRoute exact path="/users" component={UsersPage} />
          <UserRoute exact path="/users/add" component={CreateAndEditUserPage} />
          <UserRoute exact path="/users/:id" component={UserPage} />
          <UserRoute exact path="/users/:id/edit" component={CreateAndEditUserPage} />
          <UserRoute exact path="/users/:id/billing/add" component={CreateBillingPage} />
          <UserRoute exact path="/billings" component={BillingsPage} />
          <UserRoute exact path="/billings/:id" component={BillingPage} />
          <UserRoute exact path="/billings/:id/edit" component={EditBillingPage} />
          <UserRoute exact path="/reports" component={ReportsPage} />
          <UserRoute exact path="/migrate-merchants" component={MigrateMerchantsPage} />
          <Route exact path="/validator" component={ValidatorPage} />
          <Route component={() => <p>#404</p>} />
        </Switch>
      </div>
    </Router>
  </ApolloProvider>
);

export default App;
