import React from 'react';
import Page from '../components/Page';
import withUser from '../components/withUser';
import { withRouter } from 'react-router-dom';
import { useQuery, Query } from 'react-apollo';
import {
  GET_MONTHLY_REPORTS,
  GET_BILLINGS_DPP_SUMMARY_REPORT,
  GET_BILLINGS_CRN_SUMMARY_REPORT,
} from '../graphqlQueries';
import moment from 'moment';
import Flex from '../components/Flex';
import addThousandSeparatorToNumber from '../utils/addThousandSeparatorToNumber';
import DynamicTable from '@atlaskit/dynamic-table';
import Heading from '../components/Heading';
import SectionMessage from '@atlaskit/section-message';
import Button, { ButtonGroup } from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';

const ReportsPage = () => {
  const [showMore, setShowMore] = React.useState(false);
  const { data, loading, error } = useQuery(GET_MONTHLY_REPORTS);

  if (loading || error) {
    return <Page title="Reportovací sestavy" isLoading={loading} isError={error} />;
  }

  return (
    <Page title="Reportovací sestavy">
      {data && data.monthlyReports.length === 0 && (
        <SectionMessage>
          Prozatím nebyly vystavená žádná vyúčtování. Jakmile bude vystaveno alespoň jedno vyúčtování, zobrazí se zde
          přehled po měsících.
        </SectionMessage>
      )}

      {data &&
        data.monthlyReports
          .filter((_, index) => (showMore ? true : index < 1))
          .map((monthlyReport) => (
            <div key={monthlyReport.month} style={{ marginTop: 20, background: 'rgb(0, 0, 0, 0.025)', padding: 20 }}>
              <Flex style={{ marginBottom: -10 }}>
                <Heading className="first-letter-capitalized">
                  {moment.utc(monthlyReport.month).subtract(1, 'd').format('MMMM YYYY').toString()}
                </Heading>
                <div style={{ flex: '0 1 auto' }}>
                  <ButtonGroup>
                    <Query query={GET_BILLINGS_DPP_SUMMARY_REPORT} variables={{ period: monthlyReport.month }}>
                      {({ data, loading, error }) => (
                        <Button appearance={error ? 'danger' : 'primary'} isDisabled={error || loading}>
                          <a
                            href={data && data.billingsDppSummaryExcel}
                            download={`gm-vyuctovani-dpp-${monthlyReport.month}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#fff' }}
                          >
                            {loading ? <Spinner /> : 'Export platební příkazy DPP'}
                          </a>
                        </Button>
                      )}
                    </Query>
                    <Query query={GET_BILLINGS_CRN_SUMMARY_REPORT} variables={{ period: monthlyReport.month }}>
                      {({ data, loading, error }) => (
                        <Button appearance={error ? 'danger' : 'primary'} isDisabled={error || loading}>
                          <a
                            href={data && data.billingsCrnSummaryExcel}
                            download={`gm-vyuctovani-crn-${monthlyReport.month}`}
                            target="blank"
                            style={{ color: '#fff' }}
                          >
                            {loading ? <Spinner /> : 'Export platební příkazy OSVČ'}
                          </a>
                        </Button>
                      )}
                    </Query>
                  </ButtonGroup>
                </div>
              </Flex>

              <div style={{ marginTop: 15, marginBottom: -20 }}>
                <DynamicTable
                  head={{
                    cells: [
                      { key: 'name', content: 'Klient' },
                      { key: 'phone', content: 'Součet všech vyúčtování DPP', style: { textAlign: 'right' } },
                      { key: 'address', content: 'Součet všech vyúčtování OSVČ', style: { textAlign: 'right' } },
                      { key: 'role', content: 'Součet všech vyúčtování', style: { textAlign: 'right' } },
                    ],
                  }}
                  rows={[
                    ...(monthlyReport.clients &&
                      monthlyReport.clients.map((client) => ({
                        cells: [
                          { key: 'name', content: client.name },
                          {
                            key: 'phone',
                            content: addThousandSeparatorToNumber(client.dppSum) + ' Kč',
                            style: { textAlign: 'right' },
                          },
                          {
                            key: 'address',
                            content: addThousandSeparatorToNumber(client.crnSum) + ' Kč',
                            style: { textAlign: 'right' },
                          },
                          {
                            key: 'address',
                            content: addThousandSeparatorToNumber(client.crnSum + client.dppSum) + ' Kč',
                            style: { textAlign: 'right' },
                          },
                        ],
                      }))),
                    {
                      cells: [
                        { key: 'name', content: 'Celkem', style: { borderTop: '2px solid #dfe1e6' } },
                        {
                          key: 'phone',
                          content: `${addThousandSeparatorToNumber(
                            monthlyReport.clients.reduce((a, b) => a + b.dppSum, 0),
                          )} Kč`,
                          style: { textAlign: 'right', borderTop: '2px solid #dfe1e6' },
                        },
                        {
                          key: 'address',
                          content: `${addThousandSeparatorToNumber(
                            monthlyReport.clients.reduce((a, b) => a + b.crnSum, 0),
                          )} Kč`,
                          style: { textAlign: 'right', borderTop: '2px solid #dfe1e6' },
                        },
                        {
                          key: 'address',
                          content: `${addThousandSeparatorToNumber(monthlyReport.billingsSum)} Kč`,
                          style: { textAlign: 'right', borderTop: '2px solid #dfe1e6' },
                        },
                      ],
                    },
                  ]}
                  rowsPerPage={undefined}
                  defaultPage={1}
                  loadingSpinnerSize="large"
                  isLoading={loading}
                  emptyView={<div>Nemáte zatím přiřazené žádné uživatele.</div>}
                />
              </div>
            </div>
          ))}
      {!showMore && (
        <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setShowMore(true)}>Zobrazit předchozí měsíce</Button>
        </div>
      )}
    </Page>
  );
};

export default withUser(withRouter(ReportsPage), { admin: true, manager: false, merchant: false });
