import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../components/Page';
import DynamicTable from '@atlaskit/dynamic-table';
import Button, { ButtonGroup } from '@atlaskit/button';
import SearchInput, { searchData } from '../components/SearchInput';
import Icon from '../components/Icon';
import { GET_USERS } from '../graphqlQueries';
import Lozenge from '@atlaskit/lozenge';
import withUser from '../components/withUser';
import { useQuery, Query } from 'react-apollo';
import { GET_BILLINGS_SUMMARY_EXCEL_FOR_MANAGER } from '../graphqlQueries';
import moment from 'moment';
import Spinner from '@atlaskit/spinner';
import useFilter from '../components/useFilter';

const UsersPage = ({ history, user }) => {
  const [needle, setNeedle] = useState('');
  const { data, loading, error } = useQuery(GET_USERS, { pollInterval: 10000 });

  const users = (data && data && data.users) || [];

  const [UserTypeFilter, { searchValue: userType }] = useFilter({
    label: 'Typ',
    options: [
      { id: true, name: 'DPP' },
      { id: false, name: 'Živnostník' },
    ],
  });

  const [RegionalManagerFilter, { searchValue: regionalManagerValue }] = useFilter({
    label: 'Regionální manažer',
    options: users
      .map((user) => (user.manager ? user.manager.id : null))
      .filter((user, index, self) => self.indexOf(user) === index)
      .filter((user) => !!user)
      .map((userId) => {
        const user = users.find((user) => user.id === userId);

        if (!user) return false

        return { id: userId, name: user.firstName + ' ' + user.lastName };
      }).filter(user => !!user),
  });

  const search = searchData(
    users
      .filter((user) => (typeof userType === 'boolean' ? user.dpp === userType : true))
      .filter((user) =>
        regionalManagerValue
          ? user.manager
            ? user.manager.id === regionalManagerValue
              ? true
              : false
            : false
          : true,
      ),
    ['firstName', 'lastName'],
    needle,
  );

  if (error) {
    return <Page title="Uživatelé" isError />;
  }

  return (
    <Page
      title="Uživatelé"
      actions={
        <ButtonGroup>
          {(user.role === 'MANAGER' || user.role === 'ADMIN') && (
            <Query query={GET_BILLINGS_SUMMARY_EXCEL_FOR_MANAGER}>
              {({ data, loading, error }) => (
                <Button appearance={error ? 'danger' : 'default'} isDisabled={error || loading}>
                  <a
                    href={data && data.billingsSummaryExcelForManager}
                    download={`gm-vyuctovani`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {loading ? <Spinner /> : 'Stáhnout měsíční souhrn'}
                  </a>
                </Button>
              )}
            </Query>
          )}
          {user.role === 'ADMIN' && (
            <Button
              onClick={() => history.push('/users/add')}
              appearance="primary"
              iconBefore={<Icon name="plus" color="#ffffff" />}
            >
              Přidat uživatele
            </Button>
          )}
        </ButtonGroup>
      }
    >
      <div>
        <SearchInput
          value={needle}
          onChange={(e) => setNeedle(e.target.value)}
          onSubmit={() => history.push(`/users/${search[0].id}`)}
        />
        <div style={{ marginBottom: 30, display: 'flex', gap: 20 }}>
          <UserTypeFilter />
          {user.role === 'ADMIN' && <RegionalManagerFilter />}
        </div>
        <DynamicTable
          head={{
            cells: [
              { key: 'name', content: 'Jméno' },
              { key: 'address', content: 'Adresa' },
              { key: 'manager', content: 'Manažer' },
              { key: 'type', content: 'Typ' },
              { key: 'lastBilling', content: 'Poslední vyúčtování' },
              { key: 'actions', content: 'Akce', width: '210px' },
            ],
          }}
          rows={
            search &&
            search.map((user) => ({
              cells: [
                { key: 'name', content: `${user.lastName} ${user.firstName}` },
                { key: 'address', content: user.billingCity },
                { key: 'manager', content: user?.manager?.lastName },
                {
                  key: 'type',
                  content: <Lozenge appearance={user.dpp ? 'new' : 'removed'}>{user.dpp ? 'DPP' : 'Živnost'}</Lozenge>,
                },
                {
                  key: 'lastBilling',
                  content: user.lastBilling && (
                    <Lozenge
                      appearance={
                        moment.utc(user.lastBilling).subtract(1, 'month').get('month') ===
                          moment.utc().subtract(1, 'month').get('month') &&
                          moment.utc(user.lastBilling).subtract(1, 'month').get('year') ===
                          moment.utc().subtract(1, 'month').get('year')
                          ? 'success'
                          : 'moved'
                      }
                    >
                      {moment.utc(user.lastBilling).subtract(1, 'month').format('MMMM YYYY').toString()}
                    </Lozenge>
                  ),
                },
                {
                  key: 'actions',
                  content: (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <ButtonGroup>
                        {user.role === 'MERCHANT' && (
                          <a href={`/users/${user.id}/billing/add`} target="_blank" rel="noopener noreferrer">
                            <Button iconBefore={<Icon name="plus" />}>Přidat vyúčtování</Button>
                          </a>
                        )}
                        <a href={`/users/${user.id}`}>
                          <Button
                            appearance="primary"
                            iconBefore={
                              <Icon name="view-file" color="#ffffff" target="_blank" rel="noopener noreferrer" />
                            }
                          >
                            Zobrazit uživatele
                          </Button>
                        </a>
                      </ButtonGroup>
                    </div>
                  ),
                },
              ],
            }))
          }
          rowsPerPage={search.length > 13 ? 13 : undefined}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={loading}
          emptyView={<div>Nemáte zatím přiřazené žádné uživatele.</div>}
        />
      </div>
    </Page>
  );
};

export default withUser(withRouter(UsersPage), { admin: true, manager: true, merchant: false });
