import React from 'react';
import SectionMessage from '@atlaskit/section-message';

const GraphqlError = ({ error, defaultMessage }) => {
  if (!error || !error.message) {
    return null;
  }

  const userError = error.message.match(/\(User Error\)\[.+\]/g);

  const parsedError =
    userError && userError[0] ? userError[0].replace('(User Error)[', '').replace(']', '') : undefined;

  const translatedError = (message => {
    switch (message) {
      case 'User with this email address exists.':
        return 'Použijte jinou emailovou adresu. Tuto emailovou adresu už někdo používá.';
      case 'Client with same name already exists.':
        return 'Klient s tímto jménem již existuje.';
      default:
        return message;
    }
  })(parsedError);

  return <SectionMessage appearance="error">{translatedError || defaultMessage}</SectionMessage>;
};

export default GraphqlError;
