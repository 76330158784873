import * as React from 'react';
import Files from 'react-files';
import Button, { ButtonGroup } from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import { Field } from '@atlaskit/form';
import client from '../apollo-client';
import { gql } from 'apollo-boost';
import get from 'get-value';
import SectionMessage from '@atlaskit/section-message';
import Icon from './Icon';

class FileField extends React.Component {
  state = {
    hover: false,
    uploading: false,
    url: null,
    error: false,
  };

  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  componentDidMount() {
    const { defaultState } = this.props;

    if (defaultState) {
      this.setGlobalUrlState(defaultState);
    }
  }

  setGlobalUrlState(url) {
    this.props.updateParentState({
      url,
    });

    this.setState({ url });
  }

  async fileUpload(files) {
    this.setState({ uploading: true });

    if (files.length === 0) {
      this.setState({
        error: (
          <SectionMessage appearance="error">
            Tento typ souboru není povolen. Nahrajte soubor ve formátu xls nebo xlsx.
          </SectionMessage>
        ),
        uploading: false,
        hover: false,
      });
      return;
    }

    const uploadedFile = await client.mutate({
      mutation: gql`
        mutation uploadFile($file: Upload!) {
          uploadFile(file: $file)
        }
      `,
      variables: { file: files[0] },
    });

    const fileUrl = get(uploadedFile, 'data.uploadFile');

    if (fileUrl) {
      this.setState({
        uploading: false,
        hover: false,
        localName: files[0].name,
      });
      this.setGlobalUrlState(fileUrl);
    } else {
      this.setState({
        error: <SectionMessage appearance="warning">Nepovedlo se nahrát soubor.</SectionMessage>,
        uploading: false,
        hover: false,
      });
    }
  }

  render() {
    const { width, height, label, isInvalid, isRequired } = this.props;

    return (
      <Field name="favourite-color" defaultValue="" label={label} isRequired={isRequired}>
        {() => {
          if (this.state.uploading) {
            return (
              <div>
                <Spinner />
              </div>
            );
          }

          if (this.state.url) {
            return (
              <div>
                <div
                  style={{
                    padding: 10,
                    background: '#FAFBFC',
                    borderRadius: 3,
                    marginBottom: 10,
                    border: '2px solid #DDDFE4',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>Soubor s vyúčtováním úspěšně nahrán.</div>
                  <ButtonGroup>
                    <a href={this.state.url} target="_blank" rel="noopener noreferrer">
                      <Button iconBefore={<Icon name="download" />}>Stáhnout a otevřít</Button>
                    </a>
                    <Button
                      appearance="danger"
                      onClick={() => {
                        this.setGlobalUrlState('');
                      }}
                      iconBefore={<Icon name="trash" color="#ffffff" />}
                    >
                      Odebrat soubor
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            );
          }

          return (
            <div>
              {this.state.error && <div style={{ marginBottom: 10 }}>{this.state.error}</div>}
              <div
                style={{
                  background: this.state.hover ? '#EBECF0' : '#FAFBFC',
                  border: `2px ${
                    isInvalid ? 'dashed #DE360B' : this.state.hover ? 'solid #4C9AFF' : 'dashed rgb(223, 225, 230)'
                  }`,
                  borderRadius: 3,
                }}
                onDragEnter={() => this.toggleHover()}
                onDragLeave={() => this.toggleHover()}
              >
                <Files
                  className="files-dropzone"
                  onChange={event => this.fileUpload(event)}
                  onError={this.onFilesError}
                  accepts={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                  ]}
                  multiple={false}
                  maxFiles={1}
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  <div
                    style={{
                      height: height || 100,
                      width: width || '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      padding: 10,
                    }}
                  >
                    Přetáhněte soubor s vyúčtováním ze Symesu ve formátu xlsx nebo xls.
                  </div>
                </Files>
              </div>
            </div>
          );
        }}
      </Field>
    );
  }
}

export default FileField;
