import React from 'react';
import Page from '../components/Page';
import { Formik } from 'formik';
import FieldText from '@atlaskit/field-text';
import Button from '@atlaskit/button';
import Flex from '../components/Flex';
import * as Yup from 'yup';
import Error from '../components/FieldErrorMessage';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import GraphqlError from '../components/GraphqlError';
import withUser from '../components/withUser';

import { GET_CLIENT, GET_CLIENTS } from '../graphqlQueries';
import { UPDATE_CLIENT, CREATE_CLIENT } from '../graphqlMutations';

const CreateClientSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Zadejte minimálně 2 znaky')
    .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
    .required('Toto pole je povinné.'),
  dueDate: Yup.number()
    .integer()
    .min(1, 'Minimální splatnost je jeden den.')
    .max(365, 'Maximální splatnost je povolena s hodnotou 365 dnů, což odpovídá jednomu roku.')
    .required('Toto pole je povinné.'),
});

const CreateAndEditClientPage = ({ history, match }) => {
  const clientId = match.params.id;

  const fetchedClient = useQuery(GET_CLIENT, { variables: { id: clientId } });
  const [executeMutation, { loading: mutationLoading, error: mutationError }] = useMutation(
    clientId ? UPDATE_CLIENT : CREATE_CLIENT,
  );

  if ((clientId && fetchedClient.loading) || (clientId && fetchedClient.error)) {
    return <Page title="Editace klienta" isLoading={fetchedClient.loading} isError={fetchedClient.error} />;
  }

  return (
    <Page title={clientId ? 'Editace klienta' : 'Nový klient'}>
      <GraphqlError
        error={mutationError}
        defaultMessage={clientId ? 'Nepovedlo se updatovat klienta' : 'Nepodařilo se vytvořit klienta.'}
      />
      <Formik
        initialValues={{
          name: clientId ? fetchedClient.data.client.name : '',
          dueDate: clientId ? fetchedClient.data.client.dueDate : 15,
        }}
        onSubmit={async values => {
          await executeMutation({
            variables: {
              ...(clientId ? { id: clientId } : null),
              name: values.name,
              dueDate: values.dueDate,
            },
            refetchQueries: clientId
              ? [{ query: GET_CLIENT, variables: { id: clientId } }, { query: GET_CLIENTS }]
              : [{ query: GET_CLIENTS }],
            awaitRefetchQueries: true,
          });

          history.push(`/clients`);
        }}
        validationSchema={CreateClientSchema}
        render={props => (
          <form onSubmit={props.handleSubmit}>
            <Flex>
              <div>
                <FieldText
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.name}
                  name="name"
                  label="Jméno klienta"
                  isInvalid={props.errors.name && props.touched.name}
                  autoFocus={!clientId}
                  maxLength={50}
                  required
                />
                {props.errors.name && props.touched.name ? <Error>{props.errors.name}</Error> : null}
              </div>
              <div>
                <FieldText
                  type="number"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.dueDate}
                  isInvalid={props.errors.dueDate && props.touched.dueDate}
                  name="dueDate"
                  label="Splatnost ve dnech"
                  required
                />
                {props.errors.dueDate && props.touched.dueDate ? <Error>{props.errors.dueDate}</Error> : null}
              </div>
            </Flex>

            <div style={{ marginTop: 20 }}>
              <Button appearance="primary" type="submit" isDisabled={!props.isValid} isLoading={mutationLoading}>
                {clientId ? 'Upravit klienta' : 'Vytvořit klienta'}
              </Button>
            </div>
          </form>
        )}
      />
    </Page>
  );
};

export default withUser(withRouter(CreateAndEditClientPage), { admin: true, manager: false, merchant: false });
