import * as React from 'react';
import Page from '../components/Page';
import { Formik, ErrorMessage } from 'formik';
import { Button } from '@atlaskit/button/dist/cjs/components/Button';
import { useQuery } from '@apollo/react-hooks';
import { GET_BILLING, GET_CLIENTS, GET_USER, GET_INVOICE } from '../graphqlQueries';
import { withRouter } from 'react-router-dom';
import Flex from '../components/Flex';
import { Label } from '@atlaskit/field-base';
import { DatePicker } from '@atlaskit/datetime-picker';
import moment from 'moment';
import FieldErrorMessage from '../components/FieldErrorMessage';
import FileField from '../components/FileField';
import Card from '../components/Card';
import Select from '@atlaskit/select';
import FieldText from '@atlaskit/field-text';
import { Checkbox } from '@atlaskit/checkbox';
import * as Yup from 'yup';
import Heading from '../components/Heading';
import { Query, useMutation } from 'react-apollo';
import { UPDATE_BILLING } from '../graphqlMutations';
import SectionMessage from '@atlaskit/section-message';
import { Redirect } from 'react-router-dom';
import withUser from '../components/withUser';

const editBillingSchema = Yup.object().shape({
  ammount: Yup.number()
    .typeError('Zadejte kladné celé číslo.')
    .integer('Zadejte kladné celé číslo.')
    .min(1, 'Zadejte celé kladné číslo.')
    .required('Zadejte částku.'),
  firstName: Yup.string()
    .min(2, 'Zadejte minimálně 2 znaky')
    .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
    .required('Toto pole je povinné.'),
  lastName: Yup.string()
    .min(2, 'Zadejte minimálně 2 znaky')
    .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
    .required('Toto pole je povinné.'),
  email: Yup.string()
    .email('Neplatná emailová adresa.')
    .required('Toto pole je povinné.'),
  phone: Yup.string()
    .matches(/^[+]?[()/0-9. -]{12,}$/, 'Zadejte telefonní číslo ve formátu +420 xxx xxx xxx.')
    .required('Toto pole je povinné.'),
  billingStreet: Yup.string()
    .min(2, 'Zadejte minimálně 2 znaky')
    .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
    .required('Toto pole je povinné.'),
  billingCity: Yup.string()
    .min(2, 'Zadejte minimálně 2 znaky')
    .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
    .required('Toto pole je povinné.'),
  billingZipCode: Yup.string()
    .matches(/^\d{3} ?\d{2}$/, 'Zadejte PSČ ve formátu xxx xx. Povolena jsou pouze čísla.')
    .required('Toto pole je povinné.'),
  bankAccount: Yup.string()
    .matches(
      /^(([0-9]{2,6}-[0-9]{2,10})|([0-9]{2,10}))\/[0-9]{4}$/,
      'Zadejte platný bankovní účet ve formátu xxxxxx-xxxxxxxxxx/xxxx.',
    )
    .required('Toto pole je povinné.'),
  billingFile: Yup.string()
    .url('Nahrajte soubor znovu, zdá se, že se nenahrál správně.')
    .required('Nahrajte výkaz ze Symesu.'),
  clientId: Yup.string().required('Vyberte klienta.'),
  dueAt: Yup.string().required('Vyberte datum ze seznamu.'),
  dpp: Yup.boolean().required(),
  dppDeclaration: Yup.boolean(),
  personalIdentificationNumber: Yup.mixed().when('dpp', {
    is: true,
    then: Yup.string()
      .matches(/^[0-9]{6}\/[0-9]{3,4}$/, 'Zadejte platné rodné číslo ve formátu xxxxxx/xxxx.')
      .required('Toto pole je povinné.'),
    otherwise: Yup.mixed().notRequired(),
  }),
  crn: Yup.mixed().when('dpp', {
    is: false,
    then: Yup.string()
      .matches(/^[0-9]{8}$/, 'Zadejte platné IČ, které musí obsahovat přesně 8 čísel.')
      .required('Toto pole je povinné.'),
    otherwise: Yup.mixed().notRequired(),
  }),
  vatin: Yup.mixed().when('dpp', {
    is: false,
    then: Yup.string().matches(
      /^[A-Z]{2}[0-9]{8,10}$/,
      'Zadejte platné DIČ ve formátu CZxxxxxxxx. První je kód znaku velkými písmeny, posléze 8-10 číslic.',
    ),
    otherwise: Yup.mixed().notRequired(),
  }),
});

const EditBillingPage = ({ match }) => {
  const { data, loading, error } = useQuery(GET_BILLING, { variables: { id: match.params.id } });
  const [editMutation, mutationStatuses] = useMutation(UPDATE_BILLING);

  if (loading || error) {
    return <Page title="Upravit vyúčtování" isLoading={loading} isError={error} />;
  }

  const initialValues = data.billing;

  if (mutationStatuses.data) {
    return <Redirect to={`/billings/${match.params.id}`} />;
  }

  return (
    <Page title="Upravit vyúčtování">
      {mutationStatuses.error && (
        <SectionMessage appearance="error">Vyskytla se chyba. Nepovedlo se vyúčtování aktualizovat.</SectionMessage>
      )}
      <Formik
        initialValues={{
          id: match.params.id,
          ammount: initialValues.ammount,
          email: initialValues.email,
          clientId: initialValues.client.id,
          firstName: initialValues.firstName,
          lastName: initialValues.lastName,
          phone: initialValues.phone,
          billingStreet: initialValues.billingStreet,
          billingCity: initialValues.billingCity,
          billingZipCode: initialValues.billingZipCode + '',
          bankAccount: initialValues.bankAccount,
          billingFile: initialValues.billingFile,
          dpp: initialValues.dpp,
          dppDeclaration: initialValues.dppDeclaration || false,
          personalIdentificationNumber: initialValues.personalIdentificationNumber || '',
          crn: initialValues.crn || '',
          vatin: initialValues.vatin || '',
          issuedAt: initialValues.issuedAt,
          dueAt: initialValues.dueAt,
        }}
        validationSchema={editBillingSchema}
        validate={values => {
          let errors = {};

          if (moment.utc(values.dueAt).isBefore(moment.utc(values.issuedAt))) {
            errors = {
              ...errors,
              dueAt: 'Datum se splatností nemůže být nižší nebo stejné jako datum vystavení faktury.',
            };
          }

          return errors;
        }}
        onSubmit={async (values, actions) => {
          await editMutation({
            variables: {
              id: values.id,
              ammount: values.ammount,
              clientId: values.clientId,
              dueAt: values.dueAt,
              issuedAt: values.issuedAt,
              billingFile: values.billingFile,
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              billingStreet: values.billingStreet,
              billingCity: values.billingCity,
              billingZipCode: parseInt(values.billingZipCode.replace(' ', '')),
              bankAccount: values.bankAccount,
              dpp: values.dpp,
              ...(values.dpp
                ? {
                    dppDeclaration: values.dppDeclaration,
                    personalIdentificationNumber: values.personalIdentificationNumber,
                    crn: undefined,
                    vatin: undefined,
                  }
                : {
                    dppDeclaration: undefined,
                    personalIdentificationNumber: undefined,
                    crn: values.crn,
                    vatin: values.vatin,
                  }),
            },
            refetchQueries: [
              { query: GET_USER, variables: { id: data.billing.user.id } },
              { query: GET_BILLING, variables: { id: match.params.id } },
              { query: GET_INVOICE, variables: { id: match.params.id } },
            ],
          });
        }}
        isInitialValid={true}
        render={({
          errors,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldTouched,
          setFieldValue,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            <Flex>
              <Card title="Variabilní číslo">{initialValues.variableNumber}</Card>

              <Card title="Vyúčtování vystavil">
                {initialValues.issuedBy.firstName} {initialValues.issuedBy.lastName}
              </Card>
            </Flex>
            <Heading style={{ marginBottom: 0 }}>Personální informace</Heading>
            <Flex>
              <div>
                <FieldText
                  type="text"
                  name={`firstName`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  shouldFitContainer
                  placeholder=""
                  label="Křestní jméno"
                />
                <ErrorMessage component={FieldErrorMessage} name={`firstName`} />
              </div>

              <div>
                <FieldText
                  type="text"
                  name={`lastName`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  shouldFitContainer
                  placeholder=""
                  label="Příjmení"
                />
                <ErrorMessage component={FieldErrorMessage} name={`lastName`} />
              </div>

              <div>
                <FieldText
                  type="text"
                  name={`email`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  shouldFitContainer
                  placeholder=""
                  label="Email"
                />
                <ErrorMessage component={FieldErrorMessage} name={`email`} />
              </div>

              <div>
                <FieldText
                  type="text"
                  name={`phone`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  shouldFitContainer
                  placeholder=""
                  label="Telefon"
                />
                <ErrorMessage component={FieldErrorMessage} name={`phone`} />
              </div>

              <div>
                <FieldText
                  type="text"
                  name={`billingStreet`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.billingStreet}
                  shouldFitContainer
                  placeholder=""
                  label="Ulice"
                />
                <ErrorMessage component={FieldErrorMessage} name={`billingStreet`} />
              </div>

              <div>
                <FieldText
                  type="text"
                  name={`billingCity`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.billingCity}
                  shouldFitContainer
                  placeholder=""
                  label="Město"
                />
                <ErrorMessage component={FieldErrorMessage} name={`billingCity`} />
              </div>

              <div>
                <FieldText
                  type="text"
                  name={`billingZipCode`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.billingZipCode}
                  shouldFitContainer
                  placeholder=""
                  label="PSČ"
                />
                <ErrorMessage component={FieldErrorMessage} name={`billingZipCode`} />
              </div>

              <div>
                <FieldText
                  type="text"
                  name={`bankAccount`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bankAccount}
                  shouldFitContainer
                  placeholder=""
                  label="Bankovní účet"
                />
                <ErrorMessage component={FieldErrorMessage} name={`bankAccount`} />
              </div>

              <div>
                <Label label="Typ poměru" />
                <Select
                  options={[
                    { label: 'DPP - Dohoda o provedení práce', value: true },
                    { label: 'OSVČ - Osoba výdělečně činná', value: false },
                  ]}
                  name="dpp"
                  onChange={event => setFieldValue('dpp', event.value)}
                  value={
                    values.dpp === true
                      ? { label: 'DPP - Dohoda o provedení práce', value: true }
                      : { label: 'OSVČ - Osoba výdělečně činná', value: false }
                  }
                  placeholder="Typ poměru"
                />
              </div>

              <div />

              {values.dpp && (
                <div>
                  <FieldText
                    type="text"
                    name={`personalIdentificationNumber`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.personalIdentificationNumber}
                    shouldFitContainer
                    placeholder=""
                    label="Rodné číslo"
                  />
                  <ErrorMessage component={FieldErrorMessage} name={`personalIdentificationNumber`} />
                </div>
              )}

              {values.dpp && (
                <div>
                  <Label htmlFor="react-select-datepicker-1--input" label="Růžové prohlášení" />
                  <div style={{ marginTop: 10 }}>
                    <Checkbox
                      label="Poplatník podepsal růžové prohlášení"
                      name="dppDeclaration"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isChecked={values.dppDeclaration}
                    />
                  </div>
                </div>
              )}

              {!values.dpp && (
                <div>
                  <FieldText
                    type="text"
                    name="crn"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.crn}
                    shouldFitContainer
                    placeholder=""
                    label="IČ"
                  />
                  <ErrorMessage component={FieldErrorMessage} name={`crn`} />
                </div>
              )}

              {!values.dpp && (
                <div>
                  <FieldText
                    type="text"
                    name="vatin"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.vatin}
                    shouldFitContainer
                    placeholder=""
                    label="DIČ - vyplňuje pouze plátce DPH"
                  />
                  <ErrorMessage component={FieldErrorMessage} name={`vatin`} />
                </div>
              )}
            </Flex>
            <Heading style={{ marginBottom: 0 }}>Podrobnosti vyúčtování</Heading>
            <Flex>
              <div>
                <Label label="Klient" />
                <Query query={GET_CLIENTS}>
                  {({ loading, data, error }) => {
                    if (loading) {
                      return <Select placeholder="Načítám data..." />;
                    }

                    if (error) {
                      return (
                        <SectionMessage appearance="error">Vyskytla se chyba, načtěte znovu stránku.</SectionMessage>
                      );
                    }

                    return (
                      <Select
                        options={data.clients && data.clients.map(client => ({ label: client.name, value: client.id }))}
                        name={`clientId`}
                        onChange={event => setFieldValue(`clientId`, event.value)}
                        value={(function() {
                          if (values.clientId) {
                            if (data && data.clients) {
                              const client = data.clients.find(client => client.id === values.clientId, null);
                              return {
                                label: client.name,
                                value: client.id,
                              };
                            }
                          } else {
                            return null;
                          }
                        })()}
                        placeholder="Vyberte klienta"
                        shouldFitContainer
                      />
                    );
                  }}
                </Query>
                <ErrorMessage component={FieldErrorMessage} name={`clientId`} />
              </div>

              <div>
                <FieldText
                  type="number"
                  name={`ammount`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ammount}
                  shouldFitContainer
                  placeholder=""
                  label="Částka v Kč"
                />
                <ErrorMessage component={FieldErrorMessage} name={`ammount`} />
              </div>

              <div>
                <Label label="Datum vystavení" />
                <DatePicker
                  value={values.issuedAt}
                  onChange={event => {
                    setFieldValue(`issuedAt`, event);
                    setFieldTouched(`issuedAt`, true);
                  }}
                  onBlur={handleBlur}
                  dateFormat="DD. MM. YYYY"
                  placeholder="Splatnost generována podle klienta"
                  shouldFitContainer
                />
                <ErrorMessage component={FieldErrorMessage} name={`issuedAt`} />
              </div>

              <div>
                <Label label="Datum splatnosti" />
                <DatePicker
                  value={values.dueAt}
                  onChange={event => {
                    setFieldValue(`dueAt`, event);
                    setFieldTouched(`dueAt`, true);
                  }}
                  onBlur={handleBlur}
                  dateFormat="DD. MM. YYYY"
                  placeholder="Splatnost generována podle klienta"
                  shouldFitContainer
                />
                <ErrorMessage component={FieldErrorMessage} name={`dueAt`} />
              </div>
            </Flex>
            <Label label="Excel vyúčtování ze Symesu" />
            <FileField
              defaultState={values.billingFile}
              updateParentState={state => {
                setFieldValue('billingFile', state.url);
              }}
            />
            <ErrorMessage component={FieldErrorMessage} name={`summaryFile`} />
            <div style={{ marginTop: 20 }}>
              <Button
                appearance="primary"
                type="submit"
                isDisabled={!isValid}
                isLoading={loading || mutationStatuses.loading}
              >
                Odeslat vyúčtování
              </Button>
            </div>
          </form>
        )}
      />
    </Page>
  );
};

export default withUser(withRouter(EditBillingPage), { admin: true, manager: false, merchant: false });
