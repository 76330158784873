import styled from 'styled-components';

const Heading = styled.h2`
  font-size: 22px;
  color: #1b5fde;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export default Heading;
