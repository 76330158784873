import * as React from 'react';

const LoginWrapper = ({ children, title }) => (
  <div
    style={{
      minHeight: '100vh',
      background: '#F8FBFE',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}
  >
    <div
      style={{
        width: '100%',
        height: 400,
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 1,
        backgroundImage: 'url(https://proximitis.cz/images/background/banner-bg-3.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
      }}
    />
    <img
      src="/logo.png"
      style={{ width: 220, marginBottom: 50, zIndex: 2, position: 'relative' }}
      alt="Proximitis logo"
    />
    <div
      style={{
        width: '400px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        padding: '40px 30px',
        borderRadius: 10,
        zIndex: 2,
        position: 'relative',
      }}
    >
      {title && <h1 style={{ fontSize: 20, marginTop: 0 }}>{title}</h1>}
      {children}
    </div>
  </div>
);

export default LoginWrapper;
