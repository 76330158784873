import React from 'react';
import styled from 'styled-components';
import { Label } from '@atlaskit/field-base';

const Card = ({ title, children }) => (
  <Wrapper>
    <Label label={title} />
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  font-size: 15px;
`;

export default Card;
