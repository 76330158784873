import React from 'react';
import { Formik } from 'formik';
import FieldText from '@atlaskit/field-text';
import Button from '@atlaskit/button';
import { useMutation } from '@apollo/react-hooks';
import SectionMessage from '@atlaskit/section-message';
import { withRouter } from 'react-router-dom';
import LoginWrapper from '../components/LoginWrapper';
import { LOGIN } from '../graphqlMutations';
import Spinner from '@atlaskit/spinner';

const LoginPage = () => {
  const [login, { error, data, loading }] = useMutation(LOGIN);

  if (loading) {
    return (
      <LoginWrapper>
        <h1 style={{ marginBottom: 50 }}>Přihlášení</h1>
        <Spinner size="large" />
      </LoginWrapper>
    );
  }

  if (data) {
    return (
      <LoginWrapper>
        <h1 style={{ marginBottom: 50 }}>Přihlášení</h1>
        <SectionMessage appearance="confirmation">
          Poslali jsme vám přihlašovací email. Toto okno můžete zavřít a pokračovat dle intrukcí v emailu.
        </SectionMessage>
      </LoginWrapper>
    );
  }

  return (
    <LoginWrapper>
      <h1>Přihlášení</h1>
      {error && (
        <div style={{ marginTop: 50 }}>
          <SectionMessage appearance="warning">Uživatel s vámi zadanou emailovou adresou neexistuje.</SectionMessage>
        </div>
      )}
      <Formik
        initialValues={{ email: '' }}
        onSubmit={async values => {
          await login({ variables: values });
        }}
        render={props => (
          <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
            <FieldText
              type="text"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.email}
              name="email"
              label="Email"
              required
              shouldFitContainer
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 20 }}>
              <Button appearance="primary" type="submit" isLoading={loading}>
                Přihlásit se
              </Button>
            </div>
          </form>
        )}
      />
    </LoginWrapper>
  );
};

export default withRouter(LoginPage);
