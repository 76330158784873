import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import Page from '../components/Page';
import DynamicTable from '@atlaskit/dynamic-table';
import Button, { ButtonGroup } from '@atlaskit/button';
import SearchInput, { searchData } from '../components/SearchInput';
import Icon from '../components/Icon';
import { GET_BILLINGS } from '../graphqlQueries';
import withUser from '../components/withUser';
import addThousandSeparatorToNumber from '../utils/addThousandSeparatorToNumber';

const BillingsPage = ({ history }) => {
  const [needle, setNeedle] = useState('');
  const { data, loading, error } = useQuery(GET_BILLINGS);

  const billings = (data && data && data.billings) || [];

  const search = searchData(
    billings.map(billing => ({ ...billing, variableNumber: billing.variableNumber + '' })),
    ['firstName', 'lastName', 'variableNumber'],
    needle,
  );

  if (error) {
    return <Page title="Vyúčtování" isError />;
  }

  return (
    <Page title="Vyúčtování">
      <div>
        <SearchInput
          value={needle}
          onChange={e => setNeedle(e.target.value)}
          onSubmit={() => history.push(`/billings/${search[0].id}`)}
          placeholder="Vyhledávejte mezi variabilním symbolem a jménem uživatele, poté stiskněte Enter"
        />
        <DynamicTable
          head={{
            cells: [
              { key: 'variableNumber', content: 'Variabilní číslo' },
              { key: 'name', content: 'Jméno' },
              { key: 'phone', content: 'Telefon' },
              { key: 'client', content: 'Klient' },
              { key: 'ammount', content: 'Částka' },
              { key: 'actions', content: 'Action', width: '90px' },
            ],
          }}
          rows={
            search &&
            search.map(billing => ({
              cells: [
                { key: 'variableNumber', content: billing.variableNumber },
                { key: 'name', content: `${billing.firstName} ${billing.lastName}` },
                { key: 'phone', content: billing.phone },
                { key: 'client', content: billing.client.name },
                {
                  key: 'ammount',
                  content: addThousandSeparatorToNumber(billing.ammount) + ' Kč',
                  style: { textAlign: 'right' },
                },
                {
                  key: 'action',
                  content: (
                    <ButtonGroup>
                      <Button
                        appearance="primary"
                        iconBefore={<Icon name="view-file" color="#ffffff" />}
                        onClick={() => {
                          history.push(`/billings/${billing.id}`);
                        }}
                      >
                        Zobrazit vyúčtování
                      </Button>
                    </ButtonGroup>
                  ),
                },
              ],
            }))
          }
          rowsPerPage={search.length > 20 ? 20 : undefined}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={loading}
          emptyView={<div>Nenalezena žádná vyúčtování.</div>}
        />
      </div>
    </Page>
  );
};

export default withUser(withRouter(BillingsPage), { admin: true, manager: false, merchant: false });
