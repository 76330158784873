import SectionMessage from '@atlaskit/section-message';
import { Button } from '@atlaskit/button/dist/cjs/components/Button';
import React from 'react';
import Page from '../components/Page';
import withUser from '../components/withUser';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo';
import { GET_USERS } from '../graphqlQueries';
import { MOVE_USERS_FROM_MANAGER_TO_ANOTHER_ONE } from '../graphqlMutations';
import Flex from '../components/Flex';
import { Formik } from 'formik';
import Select from '@atlaskit/select';
import { Label } from '@atlaskit/field-base';

const MigrateMerchantsPage = () => {
  const { data, loading, error } = useQuery(GET_USERS);
  const [mutate, mutation] = useMutation(MOVE_USERS_FROM_MANAGER_TO_ANOTHER_ONE);

  const managerOptions =
    data &&
    data.users
      .filter((user) => user.role === 'MANAGER')
      .map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user.email }));

  return (
    <Page title="Přesun merčíků" isLoading={loading} isError={!!error}>
      {mutation.data && !mutation.loading && !mutation.error && (
        <SectionMessage appearance="confirmation">
          Přesun uživatelů z jednoho managera na druhého se povedl.
        </SectionMessage>
      )}

      {mutation.error && !mutation.loading && (
        <SectionMessage appearance="error">
          Přesun uživatelů z jednoho managera na druhého se nepovedl. Opakujte prosím akci znovu. Je možné, že manager
          měl mnoho uživatelů a v jednom cyklu se nestihli všichni převést. Pokud chyba přetrvá, kontaktujte svého
          technického správce.
        </SectionMessage>
      )}

      <Formik
        initialValues={{ oldManagerEmail: '', newManagerEmail: '' }}
        onSubmit={async (variables, formik) => {
          await mutate({ variables });
          formik.resetForm();
        }}
        render={(props) => (
          <form onSubmit={props.handleSubmit}>
            <Flex>
              <div>
                <Label label="Starý manažer" />
                <Select
                  options={managerOptions}
                  name="role"
                  onChange={(event) => props.setFieldValue('oldManagerEmail', event.value)}
                  placeholder="Jméno starého manažera"
                />
              </div>
              <div style={{ marginBottom: 20 }}>
                <Label label="Nový manažer" />
                <Select
                  options={managerOptions}
                  name="role"
                  onChange={(event) => props.setFieldValue('newManagerEmail', event.value)}
                  placeholder="Jméno nového manažera"
                />
              </div>
            </Flex>
            {props.values.oldManagerEmail && props.values.newManagerEmail && (
              <SectionMessage appearance="error" title="Přesun všech merčíků z jednoho manažera na druhého">
                <div style={{ marginBottom: 15 }}>
                  <p>
                    Touto akcí přesunete všechny merčíky od manažera{' '}
                    <strong>
                      {managerOptions.find((manager) => manager.value === props.values.oldManagerEmail).label}
                    </strong>{' '}
                    na nového manažera{' '}
                    <strong>
                      {managerOptions.find((manager) => manager.value === props.values.newManagerEmail).label}
                    </strong>
                    . Tato akce nelze odvolat.
                  </p>
                </div>
                <Button
                  appearance="danger"
                  isLoading={mutation.loading}
                  isDisabled={mutation.loading}
                  onClick={() => {
                    if (
                      window.confirm(
                        'Chcete opravdu převést všechny uživalte z jednoho manažera na druhého? Tato akce nelze odvolat!',
                      )
                    )
                      props.submitForm();
                  }}
                >
                  Převést uživatele
                </Button>
              </SectionMessage>
            )}
          </form>
        )}
      />
    </Page>
  );
};

export default withUser(withRouter(MigrateMerchantsPage), { admin: true, manager: false, merchant: false });
