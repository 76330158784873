const translateRole = role => {
  switch (role) {
    case 'MERCHANT':
      return 'Merčík';
    case 'ADMIN':
      return 'Administrátor';
    case 'MANAGER':
      return 'Manažer';
    default:
      return '';
  }
};

export default translateRole;
