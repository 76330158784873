import React from 'react';
import { GET_ME } from '../graphqlQueries';
import { Redirect } from 'react-router-dom';
import client from '../apollo-client';

const withUser = (WrappedComponent, roles = {}) => {
  class WithUser extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user: {},
        error: false,
        role: null,
      };
    }

    componentDidMount() {
      client
        .query({ query: GET_ME })
        .then(response => this.setState({ user: response.data.me, role: response.data.me.role }))
        .catch(e => this.setState({ error: true }));
    }

    render() {
      if (this.state.error) {
        return <Redirect to="/login" />;
      }

      if (!this.state.user.id) {
        return null;
      }

      if (roles.admin !== undefined && this.state.user.role === 'ADMIN' && roles.admin !== true) {
        return <Redirect to="/" />;
      }

      if (roles.manager !== undefined && this.state.user.role === 'MANAGER' && roles.manager !== true) {
        return <Redirect to="/" />;
      }

      if (roles.merchant !== undefined && this.state.user.role === 'MERCHANT' && roles.merchant !== true) {
        return <Redirect to="/" />;
      }

      return <WrappedComponent user={this.state.user} {...this.props} />;
    }
  }

  return WithUser;
};

export default withUser;
