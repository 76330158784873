import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import fetch from 'node-fetch';
import cookies from 'js-cookie';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';

const authLink = setContext((_, { headers }) => {
  var token = cookies.get('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink.concat(
      createUploadLink({
        uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        fetch,
      }),
    ),
  ]),
  cache: new InMemoryCache(),
});

export default client;
