import React from 'react';
import styled from 'styled-components';
import Flex from './Flex';
import Menu from './Menu';
import Spinner from '@atlaskit/spinner';

const Page = ({ title, children, isLoading, isError, actions }) => (
  <div>
    <Menu />
    <Wrapper>
      <Flex>
        <Title>{title}</Title>
        <ActionsWrapper>{actions}</ActionsWrapper>
      </Flex>

      {isLoading && (
        <SpinnerWrapper>
          <Spinner size="large" />
        </SpinnerWrapper>
      )}
      {isError && 'Error'}
      {!isLoading && !isError && children}
    </Wrapper>
  </div>
);

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
`;

const Title = styled.h1`
  margin-bottom: 30px;
  font-size: 30px;
`;

export default Page;
