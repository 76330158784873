import gql from 'graphql-tag';

export const CREATE_BILLINGS = gql`
  mutation createBillings($userId: ID!, $billingFile: String!, $billings: [BillingInput!]!) {
    createBillings(userId: $userId, billingFile: $billingFile, billings: $billings)
  }
`;

export const UPDATE_BILLING = gql`
  mutation updateBilling(
    $id: ID!
    $ammount: Int
    $clientId: ID
    $dueAt: DateTime
    $issuedAt: DateTime
    $billingFile: String
    $email: String
    $firstName: String
    $lastName: String
    $phone: String
    $billingStreet: String
    $billingCity: String
    $billingZipCode: Int
    $bankAccount: String
    $dpp: Boolean!
    $dppDeclaration: Boolean
    $personalIdentificationNumber: String
    $crn: String
    $vatin: String
  ) {
    updateBilling(
      id: $id
      ammount: $ammount
      clientId: $clientId
      dueAt: $dueAt
      issuedAt: $issuedAt
      billingFile: $billingFile
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      billingStreet: $billingStreet
      billingCity: $billingCity
      billingZipCode: $billingZipCode
      bankAccount: $bankAccount
      dpp: $dpp
      dppDeclaration: $dppDeclaration
      personalIdentificationNumber: $personalIdentificationNumber
      crn: $crn
      vatin: $vatin
    )
  }
`;

export const DELETE_BILLING = gql`
  mutation deleteBilling($id: ID!) {
    deleteBilling(id: $id)
  }
`;

export const CREATE_CLIENT = gql`
  mutation createClient($name: String!, $dueDate: Int!) {
    createClient(name: $name, dueDate: $dueDate) {
      id
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient($id: ID!, $name: String!, $dueDate: Int!) {
    updateClient(id: $id, name: $name, dueDate: $dueDate) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String
    $billingStreet: String
    $billingCity: String
    $billingZipCode: Int
    $dpp: Boolean
    $dppDeclaration: Boolean
    $personalIdentificationNumber: String
    $crn: String
    $vatin: String
    $bankAccount: String
    $role: Role!
    $managerId: ID
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      billingStreet: $billingStreet
      billingCity: $billingCity
      billingZipCode: $billingZipCode
      dpp: $dpp
      dppDeclaration: $dppDeclaration
      personalIdentificationNumber: $personalIdentificationNumber
      crn: $crn
      vatin: $vatin
      bankAccount: $bankAccount
      role: $role
      managerId: $managerId
    ) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String
    $billingStreet: String
    $billingCity: String
    $billingZipCode: Int
    $dpp: Boolean
    $dppDeclaration: Boolean
    $personalIdentificationNumber: String
    $crn: String
    $vatin: String
    $bankAccount: String
    $role: Role!
    $managerId: ID
  ) {
    updateUser(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      billingStreet: $billingStreet
      billingCity: $billingCity
      billingZipCode: $billingZipCode
      dpp: $dpp
      dppDeclaration: $dppDeclaration
      personalIdentificationNumber: $personalIdentificationNumber
      crn: $crn
      vatin: $vatin
      bankAccount: $bankAccount
      role: $role
      managerId: $managerId
    ) {
      id
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!) {
    login(email: $email)
  }
`;

export const MOVE_USERS_FROM_MANAGER_TO_ANOTHER_ONE = gql`
  mutation moveUsersFromManagerToAnotherOne($oldManagerEmail: String!, $newManagerEmail: String!) {
    moveUsersFromManagerToAnotherOne(oldManagerEmail: $oldManagerEmail, newManagerEmail: $newManagerEmail)
  }
`;
