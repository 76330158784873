import React from 'react';
import { Formik, Form, FieldArray, ErrorMessage } from 'formik';
import Page from '../components/Page';
import Flex from '../components/Flex';
import { Label } from '@atlaskit/field-base';
import FieldText from '@atlaskit/field-text';
import FileField from '../components/FileField';
import moment from 'moment';
import FieldErrorMessage from '../components/FieldErrorMessage';
import Select from '@atlaskit/select';
import { useQuery, useMutation } from 'react-apollo';
import Icon from '../components/Icon';
import Button from '@atlaskit/button';
import { DatePicker } from '@atlaskit/datetime-picker';
import Card from '../components/Card';
import styled from 'styled-components';
import { withRouter, Redirect } from 'react-router-dom';
import SectionMessage from '@atlaskit/section-message';
import * as Yup from 'yup';
import addThousandSeparatorToNumber from '../utils/addThousandSeparatorToNumber';
import { GET_CLIENTS, GET_USER, GET_USERS } from '../graphqlQueries';
import { CREATE_BILLINGS } from '../graphqlMutations';
import withUser from '../components/withUser';

const createBillingSchema = Yup.object().shape({
  summaryFile: Yup.string()
    .url('Nahrajte soubor znovu, zdá se, že se nenahrál správně.')
    .required('Nahrajte výkaz ze Symesu.'),
  billings: Yup.array()
    .of(
      Yup.object().shape({
        clientId: Yup.string().required('Vyberte klienta.'),
        ammount: Yup.number()
          .typeError('Zadejte kladné celé číslo.')
          .integer('Zadejte kladné celé číslo.')
          .min(1, 'Zadejte celé kladné číslo.')
          .required('Zadejte částku nebo řádek smažte.'),
        dueAt: Yup.string().required('Vyberte datum ze seznamu.'),
      }),
    )
    .min(1)
    .required(),
});

export const CreateBillingPage = ({ match, user }) => {
  const userId = match.params.id;

  const dayInMonth = moment.utc().get('D');
  const dayInMonthAddLimit = 15;

  const fetchedClients = useQuery(GET_CLIENTS);
  const fetchedUser = useQuery(GET_USER, { variables: { id: userId } });

  const [createBillings, { loading, data, error }] = useMutation(CREATE_BILLINGS);

  if (data) {
    return <Redirect to={`/users/${userId}`} />;
  }

  if (fetchedUser.loading || fetchedClients.loading) {
    return <Page isLoading={true} />;
  }

  if (fetchedUser.error || fetchedClients.error) {
    return <Page isError={true} />;
  }

  if (user.role === 'MANAGER' && dayInMonth > dayInMonthAddLimit) {
    return (
      <Page title="Přidat vyúčtování">
        <SectionMessage appearance="warning">
          Momentálně nemůžete přidávat nová vyúčtování. Je nastaveno systémové omezení do {dayInMonthAddLimit}. dne v
          měsíci a to do půlnoci. Poté se okno na přidávání nových vyúčtování otevře znovu až 1. dne nového měsíce.
        </SectionMessage>
      </Page>
    );
  }

  const emptybillingRecord = { clientId: '', ammount: '', dueAt: '' };

  return (
    <Page title="Přidat vyúčtování">
      {error && (
        <SectionMessage appearance="error">
          Vyskytla se chyba. Nepovedlo se vytvořit vyúčtování. Zkontrolujte, prosím, ručně, zdali se některé faktury
          uživateli i tak nevytvořily, aby nevznikaly duplicity. Kontaktujte prosím nadřízeného. Děkujeme.
        </SectionMessage>
      )}
      <Formik
        initialValues={{
          summaryFile: '',
          billings: [emptybillingRecord],
        }}
        validationSchema={createBillingSchema}
        validate={values => {
          let errors = {};

          if (fetchedUser.data.user.dpp) {
            const total =
              values.billings
                .map(billing => billing.ammount)
                .reduce((a, b) => (Number.isInteger(parseInt(b)) ? a + parseInt(b) : a), 0) || 0;

            if (total > 9999) {
              errors = { ...errors, dppOverLimit: true };
            }
          }

          if (values.billings.length > 0) {
            for (let i = 0; i < values.billings.length; i++) {
              if (
                values.billings[i].dueAt &&
                moment.utc(values.billings[i].dueAt).isBefore(moment.utc().startOf('month'))
              ) {
                errors = {
                  ...errors,
                  billings: {
                    ...errors.billings,
                    [i]: { dueAt: 'Datum se splatností nemůže být nižší než datum vystavení faktury.' },
                  },
                };
              }
            }
          }

          return errors;
        }}
        onSubmit={async values =>
          await createBillings({
            variables: { userId, billingFile: values.summaryFile, billings: values.billings },
            refetchQueries: [{ query: GET_USERS }, { query: GET_USER, variables: { id: userId } }],
          })
        }
        render={({ values, handleBlur, handleChange, setFieldValue, setFieldTouched, isValid, errors }) => (
          <Form>
            <h3 style={{ marginTop: 20 }}>Komu se vystavuje vyúčtování</h3>
            <Flex>
              <Card title="Komu vystavujeme vyúčtování">
                {fetchedUser.data.user.firstName} {fetchedUser.data.user.lastName}
              </Card>
              <Card title="Období">
                {moment
                  .utc()
                  .subtract(1, 'months')
                  .startOf('month')
                  .format('MMMM YYYY')
                  .toString()}
              </Card>
              <Card title="Datum vystavení faktury">
                {moment
                  .utc()
                  .startOf('month')
                  .format('DD. MM. YYYY')
                  .toString()}
              </Card>
              <Card title="Celkový součet všech položek">
                {addThousandSeparatorToNumber(
                  values.billings
                    .map(billing => billing.ammount)
                    .reduce((a, b) => (Number.isInteger(parseInt(b)) ? a + parseInt(b) : a), 0) || 0,
                )}{' '}
                Kč
              </Card>
            </Flex>

            {errors.dppOverLimit && (
              <div style={{ marginTop: 10 }}>
                <SectionMessage appearance="error">
                  Celkový součet všech částek u jednotlivých klientů nesmí celkem přesáhnout 9 999 Kč.
                </SectionMessage>
              </div>
            )}

            <FieldArray
              name="billings"
              render={arrayHelpers => (
                <div>
                  <h3 style={{ marginTop: 20, marginBottom: -20 }}>Položky</h3>

                  {values.billings && values.billings.length > 0 && (
                    <RowFlex>
                      <Label label="Klient" />
                      <Label label="Souhrnná částka za klienta v Kč" />
                      <Label label="Splatnost" />
                      <Label label="Akce" />
                    </RowFlex>
                  )}

                  {values.billings && values.billings.length > 0 ? (
                    values.billings.map((_billing, index) => (
                      <RowFlex key={index}>
                        <div>
                          <Select
                            options={
                              fetchedClients.data &&
                              fetchedClients.data.clients.map(client => ({ label: client.name, value: client.id }))
                            }
                            name={`billings.${index}.clientId`}
                            onChange={event => {
                              setFieldValue(`billings[${index}].clientId`, event.value);

                              const client = fetchedClients.data.clients.find(client => client.id === event.value);

                              console.log({ client });

                              setFieldValue(
                                `billings[${index}].dueAt`,
                                moment
                                  .utc()
                                  .startOf('month')
                                  .add(client.dueDate, 'days')
                                  .toISOString(),
                              );
                            }}
                            value={(function() {
                              if (values.billings[index].clientId) {
                                if (fetchedClients.data && fetchedClients.data.clients) {
                                  const client = fetchedClients.data.clients.find(
                                    client => client.id === values.billings[index].clientId,
                                    null,
                                  );

                                  return {
                                    label: client.name,
                                    value: client.id,
                                  };
                                }
                              } else {
                                return null;
                              }
                            })()}
                            placeholder="Vyberte klienta"
                            shouldFitContainer
                          />
                          <ErrorMessage component={FieldErrorMessage} name={`billings.${index}.clientId`} />
                        </div>

                        <div>
                          <FieldText
                            type="number"
                            name={`billings.${index}.ammount`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.billings[index].ammount}
                            shouldFitContainer
                            placeholder="Zadejte částku"
                          />
                          <ErrorMessage component={FieldErrorMessage} name={`billings.${index}.ammount`} />
                        </div>

                        <div>
                          <DatePicker
                            value={values.billings[index].dueAt}
                            onChange={event => {
                              setFieldValue(`billings[${index}].dueAt`, event);
                              setFieldTouched(`billings[${index}].dueAt`, true);
                            }}
                            onBlur={handleBlur}
                            dateFormat="DD. MM. YYYY"
                            placeholder="Splatnost generována podle klienta"
                            shouldFitContainer
                          />
                          <ErrorMessage component={FieldErrorMessage} name={`billings.${index}.dueAt`} />
                        </div>

                        <div>
                          <Button
                            type="button"
                            appearance="danger"
                            onClick={() => arrayHelpers.remove(index)}
                            style={{ height: 40, marginLeft: 15 }}
                            iconBefore={<Icon name="trash" color="#ffffff" />}
                          >
                            Odebrat položku
                          </Button>
                        </div>
                      </RowFlex>
                    ))
                  ) : (
                    <div style={{ marginTop: 30 }}>
                      <SectionMessage appearance="warning">Zadejte alespoň jednu položku vyúčtování.</SectionMessage>
                    </div>
                  )}
                  <div style={{ marginTop: 10, marginLeft: -2 }}>
                    <Button type="button" appearance="link" onClick={() => arrayHelpers.push(emptybillingRecord)}>
                      Přidat položku vyúčtování
                    </Button>
                  </div>
                </div>
              )}
            />

            <Label label="Nahrajte vyúčtování ze Symesu v Excel formátu" />
            <FileField
              updateParentState={state => {
                setFieldValue('summaryFile', state.url);
              }}
            />
            <ErrorMessage component={FieldErrorMessage} name={`summaryFile`} />

            <div style={{ marginTop: 20 }}>
              <Button appearance="primary" type="submit" isDisabled={!isValid} isLoading={loading}>
                Odeslat vyúčtování
              </Button>
            </div>
          </Form>
        )}
      />
    </Page>
  );
};

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;

  & > * {
    margin-top: 10px;

    button {
      margin: 0 !important;
    }

    label {
      display: none;
    }

    &:nth-child(1) {
      flex: 0 0 20%;
    }

    &:nth-child(2) {
      flex: 0 0 25%;
    }

    &:nth-child(3) {
      flex: 0 0 33%;
    }

    &:nth-child(4) {
      flex: 0 0 17%;
    }
  }
`;

export default withUser(withRouter(CreateBillingPage), { admin: true, manager: true, merchant: false });
