import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import Page from '../components/Page';
import translateRole from '../utils/translateRole';
import Card from '../components/Card';
import Flex from '../components/Flex';
import get from 'get-value';
import Button, { ButtonGroup } from '@atlaskit/button';
import formatAddress from '../utils/formatAddress';
import Heading from '../components/Heading';
import Icon from '../components/Icon';
import addThousandSeparatorToNumber from '../utils/addThousandSeparatorToNumber';
import DynamicTable from '@atlaskit/dynamic-table';
import moment from 'moment';
import { GET_USER } from '../graphqlQueries';
import withUser from '../components/withUser';

const UserPage = ({ history, match, user }) => {
  const { data, loading, error } = useQuery(GET_USER, { variables: { id: match.params.id } });

  if (loading || error) {
    return (
      <Page
        title={user.role === 'MERCHANT' ? 'Moje vyúčtování' : 'Detail uživatele'}
        isLoading={loading}
        isError={error}
      />
    );
  }

  const userData = get(data, 'user', { default: { manager: null } });

  return (
    <Page
      title={user.role === 'MERCHANT' ? 'Moje vyúčtování' : 'Detail uživatele'}
      actions={
        <ButtonGroup style={{ background: 'yellow' }}>
          {(user.role === 'ADMIN' || user.role === 'MANAGER') && userData.role === 'MERCHANT' && (
            <Button
              iconBefore={<Icon name="plus" />}
              onClick={() => {
                history.push(`/users/${userData.id}/billing/add`);
              }}
            >
              Přidat vyúčtování
            </Button>
          )}
          {user.role === 'ADMIN' && (
            <Button
              onClick={() => history.push(`/users/${userData.id}/edit`)}
              appearance="primary"
              iconBefore={<Icon name="pencil" color="#ffffff" />}
            >
              Editovat uživatele
            </Button>
          )}
        </ButtonGroup>
      }
    >
      <Flex>
        <Card title="Křestní jméno">{userData.firstName}</Card>
        <Card title="Příjmení">{userData.lastName}</Card>
      </Flex>
      <Flex>
        <Card title="Email">{userData.email}</Card>
        <Card title={`Role ${userData.manager ? ' (Manažer)' : ''}`}>
          {translateRole(userData.role)}{' '}
          {userData.manager && `(${userData.manager.firstName} ${userData.manager.lastName})`}
        </Card>
      </Flex>
      {userData.role === 'MERCHANT' && (
        <Flex>
          <Card title="Telefon">{userData.phone}</Card>
          <Card title="Address">{formatAddress(userData)}</Card>
        </Flex>
      )}
      {userData.dpp && (
        <div>
          <Flex>
            <Card title="DPP">{userData.dpp ? 'ANO' : 'NE'}</Card>
            <Card title="DPP - prohlášení">{userData.dppDeclaration ? 'ANO' : 'NE'}</Card>
          </Flex>
          <Flex>
            <Card title="Rodné číslo">{userData.personalIdentificationNumber}</Card>
          </Flex>
        </div>
      )}
      {userData.crn && (
        <Flex>
          <Card title="IČ">{userData.crn}</Card>
          <Card title="DIČ">{userData.vatin || 'Neplátce DPH'}</Card>
        </Flex>
      )}
      {userData.role === 'MERCHANT' && (
        <Flex>
          <Card title="Bankovní účet">{userData.bankAccount}</Card>
        </Flex>
      )}

      {userData.billings && userData.billings.length > 0 && (
        <>
          <Heading>Historická vyúčtování</Heading>
          <DynamicTable
            head={{
              cells: [
                { key: 'variableNumber', content: 'Variabilní číslo' },
                { key: 'client', content: 'Klient' },
                { key: 'period', content: 'Období' },
                { key: 'ammount', content: 'Částka' },
                { key: 'issuedBy', content: 'Vystavil' },
                { key: 'action', content: 'Akce', width: '180px' },
              ],
            }}
            rows={
              userData.billings &&
              userData.billings.map(billing => ({
                cells: [
                  { key: 'variableNumber', content: billing.variableNumber },
                  {
                    key: 'client',
                    content: billing.client.name,
                  },
                  {
                    key: 'period',
                    content: moment
                      .utc(billing.issuedAt)
                      .subtract(1, 'month')
                      .format('MMMM YYYY')
                      .toString(),
                  },
                  {
                    key: 'ammount',
                    content: `${addThousandSeparatorToNumber(billing.ammount)} Kč`,
                    style: { textAlign: 'right' },
                  },
                  {
                    key: 'issuedBy',
                    content: `${billing.issuedBy.firstName} ${billing.issuedBy.lastName}`,
                  },
                  {
                    key: 'action',
                    content: (
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          appearance="default"
                          iconBefore={<Icon name="view-file" />}
                          onClick={() => {
                            history.push(`/billings/${billing.id}`);
                          }}
                        >
                          Zobrazit vyúčtování
                        </Button>
                      </div>
                    ),
                  },
                ],
              }))
            }
            rowsPerPage={userData.billings.length > 10 ? 10 : undefined}
            defaultPage={1}
            loadingSpinnerSize="large"
            isLoading={loading}
          />
        </>
      )}
    </Page>
  );
};

export default withUser(withRouter(UserPage), { admin: true, manager: true, merchant: true });
