import React from 'react';
import Page from '../components/Page';
import { Formik } from 'formik';
import FieldText from '@atlaskit/field-text';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import Select from '@atlaskit/select';
import { Label } from '@atlaskit/field-base';
import Flex from '../components/Flex';
import * as Yup from 'yup';
import Error from '../components/FieldErrorMessage';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import GraphqlError from '../components/GraphqlError';
import { UPDATE_USER, CREATE_USER } from '../graphqlMutations';
import { GET_USERS, GET_USER } from '../graphqlQueries';
import withUser from '../components/withUser';

const CreateUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Zadejte minimálně 2 znaky')
    .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
    .required('Toto pole je povinné.'),
  lastName: Yup.string()
    .min(2, 'Zadejte minimálně 2 znaky')
    .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
    .required('Toto pole je povinné.'),
  email: Yup.string()
    .email('Neplatná emailová adresa.')
    .required('Toto pole je povinné.'),
  managerId: Yup.mixed().when('role', {
    is: 'MERCHANT',
    then: Yup.string().required('Vyberte managera.'),
    otherwise: Yup.mixed().notRequired(),
  }),
  billingStreet: Yup.mixed().when('role', {
    is: 'MERCHANT',
    then: Yup.string()
      .min(2, 'Zadejte minimálně 2 znaky')
      .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
      .required('Toto pole je povinné.'),
    otherwise: Yup.mixed().notRequired(),
  }),
  billingCity: Yup.mixed().when('role', {
    is: 'MERCHANT',
    then: Yup.string()
      .min(2, 'Zadejte minimálně 2 znaky')
      .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
      .required('Toto pole je povinné.'),
    otherwise: Yup.mixed().notRequired(),
  }),
  billingZipCode: Yup.mixed().when('role', {
    is: 'MERCHANT',
    then: Yup.string()
      .matches(/^\d{3} ?\d{2}$/, 'Zadejte PSČ ve formátu xxx xx. Povolena jsou pouze čísla.')
      .required('Toto pole je povinné.'),
    otherwise: Yup.mixed().notRequired(),
  }),
  phone: Yup.mixed().when('role', {
    is: 'MERCHANT',
    then: Yup.string()
      .matches(/^[+]?[()/0-9. -]{12,}$/, 'Zadejte telefonní číslo ve formátu +420 xxx xxx xxx.')
      .required('Toto pole je povinné.'),
    otherwise: Yup.mixed().notRequired(),
  }),
  bankAccount: Yup.mixed().when('role', {
    is: 'MERCHANT',
    then: Yup.string()
      .matches(
        /^(([0-9]{2,6}-[0-9]{2,10})|([0-9]{2,10}))\/[0-9]{4}$/,
        'Zadejte platný bankovní účet ve formátu xxxxxx-xxxxxxxxxx/xxxx.',
      )
      .required('Toto pole je povinné.'),
    otherwise: Yup.mixed().notRequired(),
  }),
  personalIdentificationNumber: Yup.mixed().when('dpp', {
    is: true,
    then: Yup.string()
      .matches(/^[0-9]{6}\/[0-9]{3,4}$/, 'Zadejte platné rodné číslo ve formátu xxxxxx/xxxx.')
      .required('Toto pole je povinné.'),
    otherwise: Yup.mixed().notRequired(),
  }),
  crn: Yup.mixed().when('dpp', {
    is: false,
    then: Yup.mixed().when('role', {
      is: 'MERCHANT',
      then: Yup.string()
        .matches(/^[0-9]{8}$/, 'Zadejte platné IČ, které musí obsahovat přesně 8 čísel.')
        .required('Toto pole je povinné.'),
      otherwise: Yup.mixed().notRequired(),
    }),
  }),
  vatin: Yup.mixed().when('dpp', {
    is: false,
    then: Yup.string().matches(
      /^[A-Z]{2}[0-9]{8,10}$/,
      'Zadejte platné DIČ ve formátu CZxxxxxxxx. První je kód znaku velkými písmeny, posléze 8-10 číslic.',
    ),
    otherwise: Yup.mixed().notRequired(),
  }),
});

const CreateAndEditUserPage = ({ history, match }) => {
  const userId = match.params.id;

  const fetchedUsers = useQuery(GET_USERS);
  const fetchedUser = useQuery(GET_USER, { variables: { id: userId } });
  const [executeMutation, { loading: mutationLoading, error: mutationError }] = useMutation(
    userId ? UPDATE_USER : CREATE_USER,
  );

  if ((userId && fetchedUser.loading) || (userId && fetchedUser.error)) {
    return <Page title="Editace uživatele" isLoading={fetchedUser.loading} isError={fetchedUser.error} />;
  }

  return (
    <Page title={userId ? 'Editace uživatele' : 'Nový uživatel'}>
      <GraphqlError
        error={mutationError}
        defaultMessage={userId ? 'Nepovedlo se updatovat uživatele' : 'Nepodařilo se vytvořit uživatele.'}
      />
      <Formik
        initialValues={{
          role: userId ? fetchedUser.data.user.role : 'MERCHANT',
          managerId: userId ? (fetchedUser.data.user.manager && fetchedUser.data.user.manager.id) || '' : '',
          firstName: userId ? fetchedUser.data.user.firstName || '' : '',
          lastName: userId ? fetchedUser.data.user.lastName || '' : '',
          email: userId ? fetchedUser.data.user.email || '' : '',
          billingStreet: userId ? fetchedUser.data.user.billingStreet || '' : '',
          billingCity: userId ? fetchedUser.data.user.billingCity || '' : '',
          billingZipCode: userId
            ? fetchedUser.data.user.billingZipCode
              ? fetchedUser.data.user.billingZipCode + ''
              : ''
            : '',
          phone: userId ? fetchedUser.data.user.phone || '+420 ' : '+420 ',
          bankAccount: userId ? fetchedUser.data.user.bankAccount || '' : '',
          personalIdentificationNumber: userId ? fetchedUser.data.user.personalIdentificationNumber || '' : '',
          dpp: userId ? fetchedUser.data.user.dpp || false : false,
          dppDeclaration: userId ? fetchedUser.data.user.dppDeclaration || false : false,
          crn: userId ? fetchedUser.data.user.crn || '' : '',
          vatin: userId ? fetchedUser.data.user.vatin || '' : '',
        }}
        onSubmit={async values => {
          const response = await executeMutation({
            variables: {
              ...(userId ? { id: userId } : null),
              role: values.role,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              billingStreet: values.role === 'MERCHANT' ? values.billingStreet : undefined,
              billingCity: values.role === 'MERCHANT' ? values.billingCity : undefined,
              billingZipCode: values.role === 'MERCHANT' ? parseInt(values.billingZipCode.replace(' ', '')) : undefined,
              phone: values.role === 'MERCHANT' ? values.phone.replace(' ', '') : undefined,
              bankAccount: values.role === 'MERCHANT' ? values.bankAccount : undefined,
              dpp: values.role === 'MERCHANT' ? values.dpp : undefined,
              personalIdentificationNumber:
                values.role === 'MERCHANT' && values.dpp === true ? values.personalIdentificationNumber : undefined,
              dppDeclaration: values.role === 'MERCHANT' && values.dpp === true ? values.dppDeclaration : undefined,
              crn: values.role === 'MERCHANT' && values.dpp === false ? values.crn : undefined,
              vatin: values.role === 'MERCHANT' && values.dpp === false ? values.vatin : undefined,
              managerId: values.role === 'MERCHANT' ? values.managerId : undefined,
            },
            refetchQueries: userId
              ? [{ query: GET_USER, variables: { id: userId } }, { query: GET_USERS }]
              : [{ query: GET_USERS }],
            awaitRefetchQueries: true,
          });

          history.push(`/users/${userId ? response.data.updateUser.id : response.data.createUser.id}`);
        }}
        validationSchema={CreateUserSchema}
        render={props => (
          <form onSubmit={props.handleSubmit}>
            <Flex>
              <div>
                <FieldText
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.firstName}
                  name="firstName"
                  label="Křestní jméno"
                  isInvalid={props.errors.firstName && props.touched.firstName}
                  autoFocus={!userId}
                  maxLength={50}
                  required
                />
                {props.errors.firstName && props.touched.firstName ? <Error>{props.errors.firstName}</Error> : null}
              </div>
              <div>
                <FieldText
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.lastName}
                  isInvalid={props.errors.lastName && props.touched.lastName}
                  name="lastName"
                  label="Příjmení"
                  maxLength={50}
                  required
                />
                {props.errors.lastName && props.touched.lastName ? <Error>{props.errors.lastName}</Error> : null}
              </div>

              <div>
                <FieldText
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.email}
                  isInvalid={props.errors.email && props.touched.email}
                  name="email"
                  label="Email"
                  required
                />
                {props.errors.email && props.touched.email ? <Error>{props.errors.email}</Error> : null}
              </div>

              <div>
                <Label label="Uživatelská role" />
                <Select
                  options={[
                    { label: 'Administrátor', value: 'ADMIN' },
                    { label: 'Manažer', value: 'MANAGER' },
                    { label: 'Merčík', value: 'MERCHANT' },
                  ]}
                  name="role"
                  onChange={event => props.setFieldValue('role', event.value)}
                  value={
                    props.values.role === 'ADMIN'
                      ? { label: 'Administrátor', value: 'ADMIN' }
                      : props.values.role === 'MANAGER'
                      ? { label: 'Manažer', value: 'MANAGER' }
                      : { label: 'Merčík', value: 'MERCHANT' }
                  }
                  placeholder="Role"
                />
              </div>
            </Flex>
            {props.values.role === 'MERCHANT' && (
              <div>
                <Flex>
                  <div>
                    <Label label="Vyberte managera" />
                    <Select
                      options={
                        fetchedUsers.data && fetchedUsers.data.users
                          ? [
                              ...fetchedUsers.data.users
                                .filter(user => user.role === 'MANAGER')
                                .map(user => ({
                                  label: `${user.firstName} ${user.lastName}`,
                                  value: user.id,
                                })),
                            ]
                          : {}
                      }
                      name="managerId"
                      onChange={event => props.setFieldValue('managerId', event.value)}
                      value={(function() {
                        if (props.values.managerId) {
                          if (fetchedUsers.data && fetchedUsers.data.users) {
                            const user = fetchedUsers.data.users.find(user => user.id === props.values.managerId, {
                              label: '',
                              value: '',
                            });

                            return {
                              label: `${user.firstName} ${user.lastName}`,
                              value: user.id,
                            };
                          }
                        } else {
                          return { label: '', value: '' };
                        }
                      })()}
                      placeholder="Role"
                      isLoading={fetchedUsers.loading}
                    />
                  </div>

                  <div>
                    <FieldText
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.billingStreet}
                      isInvalid={props.errors.billingStreet && props.touched.billingStreet}
                      name="billingStreet"
                      maxLength={50}
                      label="Ulice"
                      required
                    />
                    {props.errors.billingStreet && props.touched.billingStreet ? (
                      <Error>{props.errors.billingStreet}</Error>
                    ) : null}
                  </div>

                  <div>
                    <FieldText
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.billingCity}
                      isInvalid={props.errors.billingCity && props.touched.billingCity}
                      name="billingCity"
                      maxLength={50}
                      label="Město"
                      required
                    />
                    {props.errors.billingCity && props.touched.billingCity ? (
                      <Error>{props.errors.billingCity}</Error>
                    ) : null}
                  </div>

                  <div>
                    <FieldText
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.billingZipCode}
                      isInvalid={props.errors.billingZipCode && props.touched.billingZipCode}
                      name="billingZipCode"
                      label="PSČ"
                      required
                    />
                    {props.errors.billingZipCode && props.touched.billingZipCode ? (
                      <Error>{props.errors.billingZipCode}</Error>
                    ) : null}
                  </div>

                  <div>
                    <FieldText
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.phone}
                      isInvalid={props.errors.phone && props.touched.phone}
                      name="phone"
                      label="Telefon"
                      required
                    />
                    {props.errors.phone && props.touched.phone ? <Error>{props.errors.phone}</Error> : null}
                  </div>

                  <div>
                    <FieldText
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.bankAccount}
                      isInvalid={props.errors.bankAccount && props.touched.bankAccount}
                      name="bankAccount"
                      label="Číslo účtu"
                      required
                    />
                    {props.errors.bankAccount && props.touched.bankAccount ? (
                      <Error>{props.errors.bankAccount}</Error>
                    ) : null}
                  </div>

                  <div>
                    <Label label="Typ poměru" />
                    <Select
                      options={[
                        { label: 'DPP - Dohoda o provedení práce', value: true },
                        { label: 'OSVČ - Osoba výdělečně činná', value: false },
                      ]}
                      name="dpp"
                      onChange={event => props.setFieldValue('dpp', event.value)}
                      value={
                        props.values.dpp === true
                          ? { label: 'DPP - Dohoda o provedení práce', value: true }
                          : { label: 'OSVČ - Osoba výdělečně činná', value: false }
                      }
                      placeholder="Typ poměru"
                    />
                  </div>
                </Flex>

                {props.values.dpp === true && (
                  <div>
                    <Flex>
                      <div>
                        <FieldText
                          type="text"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.personalIdentificationNumber}
                          isInvalid={
                            props.errors.personalIdentificationNumber && props.touched.personalIdentificationNumber
                          }
                          name="personalIdentificationNumber"
                          label="Rodné číslo"
                          required
                        />
                        {props.errors.personalIdentificationNumber && props.touched.personalIdentificationNumber ? (
                          <Error>{props.errors.personalIdentificationNumber}</Error>
                        ) : null}
                      </div>

                      <div>
                        <Label htmlFor="react-select-datepicker-1--input" label="Růžové prohlášení" />
                        <div style={{ marginTop: 10 }}>
                          <Checkbox
                            label="Poplatník podepsal růžové prohlášení"
                            name="dppDeclaration"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            isChecked={props.values.dppDeclaration}
                          />
                        </div>
                      </div>
                    </Flex>
                  </div>
                )}

                {props.values.dpp === false && (
                  <Flex>
                    <div>
                      <FieldText
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.crn}
                        isInvalid={props.errors.crn && props.touched.crn}
                        name="crn"
                        label="IČ"
                        required
                      />
                      {props.errors.crn && props.touched.crn ? <Error>{props.errors.crn}</Error> : null}
                    </div>

                    <div>
                      <FieldText
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.vatin}
                        isInvalid={props.errors.vatin && props.touched.vatin}
                        name="vatin"
                        label="DIČ - vyplňuje pouze plátce DPH"
                      />
                      {props.errors.vatin && props.touched.vatin ? <Error>{props.errors.vatin}</Error> : null}
                    </div>
                  </Flex>
                )}
              </div>
            )}
            <div style={{ marginTop: 20 }}>
              <Button appearance="primary" type="submit" isDisabled={!props.isValid} isLoading={mutationLoading}>
                {userId ? 'Upravit uživatele' : 'Vytvořit uživatele'}
              </Button>
            </div>
          </form>
        )}
      />
    </Page>
  );
};

export default withUser(withRouter(CreateAndEditUserPage), { admin: true, manager: false, merchant: false });
