import * as React from 'react';
import Page from '../components/Page';
import { withRouter, Redirect } from 'react-router-dom';
import { useQuery, Query, Mutation } from 'react-apollo';
import { GET_BILLING, GET_INVOICE, GET_USER } from '../graphqlQueries';
import { ButtonGroup } from '@atlaskit/button';
import { Button } from '@atlaskit/button/dist/cjs/components/Button';
import Flex from '../components/Flex';
import Card from '../components/Card';
import Heading from '../components/Heading';
import addThousandSeparatorToNumber from '../utils/addThousandSeparatorToNumber';
import moment from 'moment';
import Icon from '../components/Icon';
import withUser from '../components/withUser';
import SectionMessage from '@atlaskit/section-message';
import Spinner from '@atlaskit/spinner';
import { DELETE_BILLING } from '../graphqlMutations';

const BillingPage = ({ match, history, user }) => {
  const { data, loading, error } = useQuery(GET_BILLING, { variables: { id: match.params.id } });

  if (loading || error) {
    return <Page title="Detail vyúčtování" isLoading={loading} isError={error} />;
  }

  const userId = data.billing.user.id;

  const billing = data.billing;

  return (
    <Page
      title="Detail vyúčtování"
      actions={
        <ButtonGroup>
          {(user.role === 'ADMIN' || user.role === 'MANAGER') && (
            <Button
              onClick={() => history.push(`/users/${data.billing.user.id}`)}
              iconBefore={<Icon name="view-file" />}
            >
              Zobrazit uživatele
            </Button>
          )}
          {user.role === 'ADMIN' && (
            <Button
              onClick={() => history.push(`/billings/${match.params.id}/edit`)}
              iconBefore={<Icon name="pencil" color="#ffffff" />}
              appearance="primary"
            >
              Upravit vyúčtování
            </Button>
          )}
        </ButtonGroup>
      }
    >
      <Flex>
        <Card title="Variabilní číslo">{billing.variableNumber}</Card>
        <Card title="Vystavil">
          {billing.issuedBy.firstName} {billing.issuedBy.lastName}
        </Card>
        <Card title="Vystaveno dne">
          {moment(billing.createdAt)
            .format('DD. MM. YYYY kk:mm')
            .toString()}
        </Card>
        <Card title="Aktualizováno dne">
          {moment(billing.updatedAt)
            .format('DD. MM. YYYY kk:mm')
            .toString()}
        </Card>
      </Flex>

      <Heading style={{ marginBottom: 0 }}>Ke stažení</Heading>
      <Flex>
        <Card title="Excel vyúčtování ze Symesu">
          <a
            href={billing.billingFile}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginTop: 5, display: 'block' }}
          >
            <Button iconBefore={<Icon name="download" />}>Stáhnout a otevřít</Button>
          </a>
        </Card>
        {!billing.dpp && (
          <Query query={GET_INVOICE} variables={{ id: billing.id }}>
            {({ data, loading, error }) => (
              <Card title="PDF faktura">
                <a
                  href={data && data.invoice}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginTop: 5, display: 'block' }}
                >
                  <Button iconBefore={<Icon name="download" />} isLoading={loading} isDisabled={error || loading}>
                    Stáhnout a otevřít
                  </Button>
                </a>
              </Card>
            )}
          </Query>
        )}
      </Flex>

      <Heading style={{ marginBottom: 0 }}>Podrobnosti vyúčtování</Heading>
      <Flex>
        <Card title="Klient">{billing.client.name}</Card>
        <Card title="Částka">{addThousandSeparatorToNumber(billing.ammount)} Kč</Card>
        <Card title="Datum vystavení">
          {moment
            .utc(billing.issuedAt)
            .format('DD. MM. YYYY')
            .toString()}
        </Card>
        <Card title="Datum splatnosti">
          {moment
            .utc(billing.dueAt)
            .format('DD. MM. YYYY')
            .toString()}
        </Card>
      </Flex>

      <Heading style={{ marginBottom: 0 }}>Personální informace</Heading>
      <Flex>
        <Card title="Křestní jméno">{billing.firstName}</Card>
        <Card title="Příjmení">{billing.lastName}</Card>
        <Card title="Email">{billing.email}</Card>
        <Card title="Telefon">{billing.phone}</Card>
        <Card title="Ulice">{billing.billingStreet}</Card>
        <Card title="Město">{billing.billingCity}</Card>
        <Card title="PSČ">{billing.billingZipCode}</Card>
        <Card title="Bankovní účet">{billing.bankAccount}</Card>
        <Card title="Typ poměru">
          {billing.dpp ? 'DPP - Dohoda o provedení práce' : 'OSVČ - Osoba výdělečně činná'}
        </Card>

        {billing.dpp && <Card title="Rodné číslo">{billing.bankAccount}</Card>}
        {billing.dpp && <Card title="Růžové prohlášení">{billing.dppDeclaration ? 'Ano' : 'Ne'}</Card>}
        {!billing.dpp && <Card title="IČ">{billing.crn}</Card>}
        {!billing.dpp && <Card title="DIČ">{billing.vatin || 'Neplátce DPH'}</Card>}
      </Flex>

      {user.role === 'ADMIN' && (
        <>
          <Heading>Nebezpečná zóna</Heading>
          <SectionMessage appearance="error" title="Smazání vyúčtování">
            <div style={{ marginBottom: 15 }}>
              <p>
                V této sekci máte možnost smazat toto konkrétní vyúčtování. Po smazání již není možné vyúčtování
                obnovit. Při smazání vyúčtování zároveň vytvoříte mezeru v číslování vyúčtování (variabilní symboly),
                která bude navždy volná a nevyužitá.
              </p>
            </div>
            <Mutation
              mutation={DELETE_BILLING}
              refetchQueries={[{ query: GET_USER, variables: { id: data.billing.user.id } }]}
            >
              {(deleteBillingMutation, { data, loading, error, called }) => {
                if (error) {
                  return <div style={{ color: '#bf2700' }}>Nepovedlo se smazat vyúčtování.</div>;
                }

                if (loading) {
                  return <Spinner />;
                }

                if (data) {
                  return <Redirect to={`/users/${userId}`} />;
                }

                if (!called) {
                  return (
                    <Button
                      appearance="danger"
                      onClick={() => {
                        if (window.confirm('Chcete smazat toto vyúčtování? Tato akce je neobnovitelná.'))
                          deleteBillingMutation({ variables: { id: match.params.id } });
                      }}
                    >
                      Smazat vyúčtování
                    </Button>
                  );
                }

                return null;
              }}
            </Mutation>
          </SectionMessage>
        </>
      )}
    </Page>
  );
};

export default withUser(withRouter(BillingPage), { admin: true, manager: true, merchant: true });
