import * as React from 'react';
import FuzzySearch from 'fuzzy-search';

export const searchData = (data, searchKeys, needle) => {
  const searcher = new FuzzySearch(data, searchKeys, {
    sort: true,
  });

  return searcher.search(needle);
};

const SearchInput = ({ value, onChange, onSubmit, placeholder = 'Zadejte jméno a stiskněte Enter' }) => (
  <form onSubmit={onSubmit} style={{ position: 'relative', display: 'flex' }}>
    <img
      src="https://img.icons8.com/ios-glyphs/60/0065ff/search.png"
      style={{ position: 'absolute', left: 15, top: 20, width: 30, hegiht: 30 }}
      alt="Search Icon"
    />
    <input
      value={value}
      onChange={onChange}
      autoFocus={true}
      placeholder={placeholder}
      style={{
        flex: '1 1 100%',
        padding: '20px 20px 20px 60px',
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderRadius: 10,
        border: '2px solid #0065ff',
        outline: 'none',
        marginBottom: 25,
        fontSize: 20,
      }}
    />
  </form>
);

export default SearchInput;
