import { withRouter } from 'react-router-dom';
import cookie from 'js-cookie';

const setUserTokenPage = ({ match, history }) => {
  const token = match.params.token;
  cookie.set('token', token);
  history.push('/');
  return null;
};

export default withRouter(setUserTokenPage);
