import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }

  & > div {
    flex: 0 0 48%;

    @media screen and (max-width: 600px) {
      flex: 0 0 100%;
    }

    [class^='Content__'],
    [class^='Content-'] {
      width: 100% !important;
    }
  }
`;

export default Flex;
