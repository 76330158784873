import * as React from 'react';
import Select from 'react-select';

function useFilter({ options, isLoading, queryKey, label }) {
  const [queryValue, setQueryValue] = React.useState();

  return [
    () => (
      <div style={{ flex: '1 1 auto' }}>
        <div style={{ fontWeight: 'bold', marginBottom: 2 }}>{label}</div>
        <Select
          instanceId={queryKey}
          options={options?.map((option) => ({ label: option.name, value: option.id })) || []}
          value={
            options
              ?.map((option) => ({ label: option.name, value: option.id }))
              .find((option) => option.value === queryValue) || null
          }
          onChange={(option) => {
            if (!option) {
              setQueryValue(null);
            } else {
              setQueryValue(option.value);
            }
          }}
          isClearable
          isLoading={!!isLoading}
        />
      </div>
    ),
    {
      searchValue: queryValue,
    },
  ];
}

export default useFilter;
