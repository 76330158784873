import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import Page from '../components/Page';
import DynamicTable from '@atlaskit/dynamic-table';
import Button, { ButtonGroup } from '@atlaskit/button';
import moment from 'moment';
import Icon from '../components/Icon';
import { GET_CLIENTS } from '../graphqlQueries';
import withUser from '../components/withUser';

const ClientsPage = ({ history }) => {
  const { data, loading, error } = useQuery(GET_CLIENTS);

  const clients = (data && data && data.clients) || [];

  if (error) {
    return <div>Error</div>;
  }

  return (
    <Page
      title="Klienti"
      actions={
        <ButtonGroup>
          <Button
            onClick={() => history.push('/clients/add')}
            appearance="primary"
            iconBefore={<Icon name="plus" color="#ffffff" />}
          >
            Přidat klienta
          </Button>
        </ButtonGroup>
      }
    >
      <div>
        <DynamicTable
          head={{
            cells: [
              { key: 'name', content: 'Jméno' },
              { key: 'dueDate', content: 'Splatnost v dnech', width: '150px' },
              { key: 'createdAt', content: 'Datum vytvoření', width: '150px' },
              { key: 'actions', content: 'Action', width: '150px' },
            ],
          }}
          rows={
            clients &&
            clients.map(client => ({
              cells: [
                { key: 'name', content: client.name },
                { key: 'dueDate', content: client.dueDate },
                {
                  key: 'dueDate',
                  content: moment
                    .utc(client.createdAt)
                    .format('DD. MM. YYYY')
                    .toString(),
                },
                {
                  key: 'action',
                  content: (
                    <ButtonGroup>
                      <Button
                        iconBefore={<Icon name="pencil" />}
                        onClick={() => {
                          history.push(`/clients/${client.id}/edit`);
                        }}
                      >
                        Upravit
                      </Button>
                    </ButtonGroup>
                  ),
                },
              ],
            }))
          }
          rowsPerPage={clients.length > 10 ? 10 : undefined}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={loading}
        />
      </div>
    </Page>
  );
};

export default withUser(withRouter(ClientsPage), { admin: true, manager: false, merchant: false });
