import React from 'react';
import { withRouter } from 'react-router-dom';
import cookies from 'js-cookie';
import { ButtonGroup } from '@atlaskit/button';
import Icon from './Icon';
import withUser from './withUser';

const MenuFlexDividier = () => <div style={{ height: 10, flex: '1 1 auto' }} />;

const MenuItem = withRouter(({ to, onClick, icon, history, text, active }) => (
  <button
    onClick={(e) => {
      if (to) {
        history.push(to);
      }

      if (onClick) {
        e.preventDefault();
        onClick();
      }
    }}
    style={{
      color: active ? '#ffffff' : '#ffffff',
      border: 'none',
      background: active ? 'rgba(0,0,0, 0.2)' : 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 16,
      padding: '18px 20px',
      margin: 0,
      cursor: 'pointer',
    }}
  >
    {icon && <Icon name={icon} color="#ffffff" siz={50} style={{ marginRight: 10 }} />}

    {text}
  </button>
));

const Menu = ({ history, match, user }) => {
  const signOut = () => {
    cookies.remove('token');
    history.push('/login');
  };

  return (
    <div
      style={{
        backgroundColor: '#0065ff',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          listStyle: 'none',
          margin: '0',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {['ADMIN', 'MANAGER'].includes(user.role) && (
          <MenuItem
            text={`Uživatelé`}
            icon="groups"
            onClick={() => history.push('/users')}
            active={match.path.includes('/users')}
          />
        )}
        {['ADMIN'].includes(user.role) && (
          <MenuItem
            text={`Vyúčtování`}
            icon="invoice"
            onClick={() => history.push('/billings')}
            active={match.path.includes('/billings')}
          />
        )}
        {['MERCHANT'].includes(user.role) && (
          <MenuItem text={`Moje vyúčtování`} icon="bill" onClick={() => history.push(`/users/${user.id}`)} />
        )}
        {['ADMIN'].includes(user.role) && (
          <MenuItem
            text={`Reportovací sestavy`}
            icon="download-pie-chart-report"
            onClick={() => history.push('/reports')}
            active={match.path.includes('/reports')}
          />
        )}
        {['ADMIN'].includes(user.role) && (
          <MenuItem
            text={`Klienti`}
            icon="client-management"
            onClick={() => history.push('/clients')}
            active={match.path.includes('/clients')}
          />
        )}
        {['ADMIN'].includes(user.role) && (
          <MenuItem
            text={`Přesun merčíků`}
            icon="trekking"
            onClick={() => history.push('/migrate-merchants')}
            active={match.path.includes('/migrate-merchants')}
          />
        )}

        <MenuFlexDividier />
        <MenuFlexDividier />
        <ButtonGroup>
          <MenuItem text="Odhlásit se" icon="export" onClick={signOut} />
        </ButtonGroup>
      </div>
    </div>
  );
};

export default withUser(withRouter(Menu));
