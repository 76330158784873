import React from 'react';

const Icon = ({ name, color = '#515f79', size = 20, style }) => (
  <img
    src={`https://img.icons8.com/ios/${size * 2}/${color.replace('#', '')}/${name}.png`}
    style={{ width: size, height: size, ...style }}
    alt={`Ikona ${name}`}
  />
);

export default Icon;
